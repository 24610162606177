<template>
	<div class="d-md-flex align-items-center justify-content-between gap-4 mt-2 mb-4">
		<div>
			<router-link :to="path" class="text-dark m-auto"><i class="ri-arrow-left-line fs-24"></i></router-link>
			<h2 class="main-title fs-28 mb-0 ms-1" style="display: inline;">Rowdy Details</h2>
		</div>
		<!-- <button type="button" class="mt-auto btn btn-outline-primary d-flex align-items-center gap-2">
            <i class="ri-add-line fs-18 lh-1"></i>Edit
        </button> -->
		<div v-can="'rowdy.update'">
			<router-link :to="'/update_cases_processes/' + rowdy.rowdy_id"
				class="mt-auto btn btn-outline-primary d-flex align-items-center gap-2"><i
					class="ri-edit-line fs-18 lh-1"></i>Edit</router-link>
		</div>
	</div>
	<div class="col-xl-12">
		<div>
			<div class="card card-one">
				<div class="card-header d-flex justify-content-end">
					<button v-can="'rowdy.remarks'" class="btn btn-sm btn-primary me-2" data-bs-toggle="modal"
						data-bs-target="#viewRemarks" @click="getUserRemarks(rowdy.rowdy_id)">User Remarks</button>
					<button v-can="'rowdy.export_profile'" class="btn btn-sm btn-success mx-2"
						@click="exportRowdiesCasesPdf()">Export Profile</button>
					<button v-can="'rowdy.export_profile'" class="btn btn-sm btn-primary me-2"
						@click="printViewPdf()">Print Profile</button>
					<!-- <button class="btn btn-sm btn-success" @click="downloadSheets()">Export Details (excel)</button> -->
				</div>
				<div class="card-body" style="overflow-y:scroll; height: 85vh;">
					<div class="row">
						<div class="col-sm-3">
							<div class="card mt-3">
								<a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#enlargeImageModal"
									@click="getRowdyImages(rowdy.rowdy_id)">
									<img :src="rowdy.rowdy_sheeter_photo ? rowdy.rowdy_sheeter_photo : '/assets/img/avatar.jpg'"
										alt="user-avatar" width="100%" id="uploadedAvatar" />
								</a>
								<label class="text-center m-2">AGE : {{ rowdy.age }}</label>
							</div>
						</div>
						<div class="col-sm-9">
							<div class="row">
								<div class="col-6 my-2">
									<label for="" class="form-label">FPB Coid Number</label>
									<label for="" class="form-control">{{ rowdy.fpb_coid_number }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Rowdy Sheeter Name</label>
									<label for="" class="form-control">{{ rowdy.rowdy_sheeter_name }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Alias</label>
									<label for="" class="form-control">{{ rowdy.alias }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Father Name</label>
									<label for="" class="form-control">{{ rowdy.father_name }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Gender</label>
									<label for="" class="form-control">{{ rowdy.gender }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Mobile Number</label>
									<label for="" class="form-control">{{ rowdy.mobile_no }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Aadhaar</label>
									<label for="" class="form-control">{{ rowdy.aadhaar_number }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Voter ID</label>
									<label for="" class="form-control">{{ rowdy.voter_card_no }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">locality</label>
									<label for="" class="form-control">{{ rowdy.locality }}&nbsp;</label>
								</div>
							</div>
						</div>
						<div class="col-12 my-2 mt-4">
							<label for="" class="form-label">Present Address</label>
							<label for="" class="form-control">{{ rowdy.present_address }}&nbsp;</label>
						</div>
						<div class="col-12 my-2 mt-4">
							<label for="" class="form-label">Permanent Address</label>
							<label for="" class="form-control">{{ rowdy.permanent_address }}&nbsp;</label>
						</div>
						<div class="col-12 my-2 mt-4">
							<label for="" class="form-label">Short Notes / Remarks</label>
							<label for="" class="form-control">{{ rowdy.remarks }}&nbsp;</label>
						</div>
					</div>
					<div class="small">
						<h6 class="mt-3">CATEGORY</h6>
						<table class="table table-hover table-sm table-bordered table-striped mt-2 mb-0">
							<thead>
								<tr style="background-color: #f5f5f5;">
									<th class="text-center">SL NO</th>
									<th>Openned Date</th>
									<th>Police Station</th>
									<th>Category</th>
									<th>Closed Date</th>
									<th>Rowdy Status</th>
									<th>Where About?</th>
									<th>Where About<br>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="rowdy_categories.length" v-for="category, key in rowdy_categories" :key="key">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ moment(category.openned_date) }}</td>
									<td v-if="category.police_station.police_station == 'Others'">{{
										category.other_police_station }}</td>
									<td v-else>{{ category.police_station.police_station }}</td>
									<td class="text-center">{{ category.category }}</td>
									<td>{{ moment(category.closed_date) }}</td>
									<td>{{ category.rowdy_status }}</td>
									<td>{{ category.where_abouts }}</td>
									<td v-if="category.where_abouts == 'OV'"
										v-html="formatHowLong(category.wa_type?.how_long)"></td>
									<td v-else-if="category.where_abouts == 'JC'"
										v-html="formatCaseUtp(category.wa_type?.case_utp)"></td>
									<td v-else>{{ category.wa_type.occupation }}</td>
								</tr>
								<tr v-else>
									<td colspan="6" class="text-center">No records to display!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="small">
						<h6 class="mt-3">CASES</h6>
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead>
								<tr>
									<th class="text-center">Sl No.</th>
									<th>&nbsp;&nbsp;&nbsp;FIR Date&nbsp;&nbsp;&nbsp;</th>
									<!-- <th>FIR Stage</th> -->
									<th>Police Station</th>
									<th>Crime Number</th>
									<th>Section of Law</th>
									<th class="text-center">Warrant Issued</th>
									<!-- <th>Crime Head</th> -->
									<th>Victim/Deceased</th>
									<th>Accomplices Names</th>
									<th>Case Status</th>
									<th>Court Name</th>
									<th class="text-center">CC/SC <br> Number</th>
									<th>Next Hearing Date</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="cases.length" v-for="rowdy_case, key in cases">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ moment(rowdy_case.fir_date) }}</td>
									<!-- <td>{{ rowdy_case.fir_stage }}</td> -->
									<td v-if="rowdy_case.police_station?.police_station == 'Others'">{{
										rowdy_case.other_police_station }}</td>
									<td v-else>{{ rowdy_case.police_station?.police_station }}</td>
									<td>{{ rowdy_case.crime_number }}</td>
									<td>{{ rowdy_case.section_of_law }}</td>
									<td class="text-center">{{ rowdy_case.warrant_issued ? 'Yes' : 'No' }}</td>
									<td>{{ rowdy_case.victim_deceased }}</td>
									<td>{{ rowdy_case.accomplices_names }}</td>
									<td>{{ rowdy_case.case_status }}</td>
									<td>{{ rowdy_case.court_name }}</td>
									<td>{{ rowdy_case.cc_sc_number }}</td>
									<td>{{ moment(rowdy_case.next_hearing_date) }}</td>
								</tr>
								<tr v-else>
									<td colspan="12" class="text-center">No records to display!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="small">
						<h6 class="mt-3">PROCESSES</h6>
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead>
								<tr>
									<th class="text-center">SL NO</th>
									<th>Process Type</th>
									<th>Police Station</th>
									<th>Crime Number</th>
									<th>Section of Law</th>
									<th>Court Name</th>
									<th class="text-center">CC/SC <br> Number</th>
									<th>Issued Date</th>
									<th>Served/<br>Unserved</th>
									<th>Next Hearing Date</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="processes.length" v-for="process, key in processes">
									<td>{{ key + 1 }}</td>
									<td>{{ process.process_type }}</td>
									<td>{{ process.police_station?.police_station }}</td>
									<td>{{ process.crime_number }}</td>
									<td>{{ process.section_of_law }}</td>
									<td>{{ process.court_name }}</td>
									<td>{{ process.cc_sc_number }}</td>
									<td>{{ moment(process.issued_date) }}</td>
									<td>{{ process.status }}</td>
									<td>{{ moment(process.next_hearing_date) }}</td>
								</tr>
								<tr v-else>
									<td colspan="10" class="text-center">No records to display!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="small">
						<h6 class="mt-3">GOONDA ACT/EXTERNMENT/KCOCA Act</h6>
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead>
								<tr>
									<th>SL NO</th>
									<th>Type</th>
									<th>Police Station</th>
									<th>Person Name</th>
									<th>Father Name</th>
									<th>Address</th>
									<th>Activities</th>
									<th>Order No</th>
									<th>Approved/Rejected</th>
									<th>Which Place</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="goondas.length" v-for="goonda, key in goondas">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ goonda.type }}</td>
									<td>{{ goonda.police_station.police_station }}</td>
									<td>{{ goonda.person_name }}</td>
									<td>{{ goonda.father_name }}</td>
									<td>{{ goonda.address }}</td>
									<td>{{ goonda.activities }}</td>
									<td>{{ goonda.order_no }}</td>
									<td>{{ goonda.approved_status }}</td>
									<td>{{ goonda.which_place }}</td>
								</tr>
								<tr v-else>
									<td colspan="10 " class="text-center">No records to display!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="small">
						<h6 class="mt-3">PAR CASES</h6>
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead>
								<tr class="text-center">
									<th rowspan="2" class="text-center">Sl No.</th>
									<th rowspan="2">Police Station</th>
									<th rowspan="2">PAR No.</th>
									<th rowspan="2">Year</th>
									<th rowspan="2">Section<br>Of Law</th>
									<th rowspan="2">PAR Date</th>
									<th colspan="2">Bound Over Date</th>
									<th rowspan="2">Surety Details</th>
									<th rowspan="2">If Violated</th>
									<th rowspan="2">Date</th>
								</tr>
								<tr class="text-center">
									<th>From</th>
									<th>To</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="par_case, key in par_cases" v-if="par_cases.length">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ par_case.police_station?.police_station }}</td>
									<td>{{ par_case.par_no }}</td>
									<td>{{ par_case.year }}</td>
									<td>{{ par_case.section_of_law }}</td>
									<td>{{ moment(par_case.par_date) }}</td>
									<td>{{ moment(par_case.bound_over_from) }}</td>
									<td>{{ moment(par_case.bound_over_to) }}</td>
									<td>{{ par_case.surety_details }}</td>
									<td>{{ par_case.if_violated }}</td>
									<td>{{ moment(par_case.violated_date) }}</td>
								</tr>
								<tr v-else>
									<td colspan="13" class="text-center">No records to display!...</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- View remarks modal -->
	<div class="modal fade" id="viewRemarks" tabindex="-1" aria-labelledby="viewRemarksModal" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title fs-5" id="viewRemarksModal">Remarks</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12">
									<textarea name="" class="form-control" id="" cols="30" rows="2"
										placeholder="Enter user remarks" v-model="user_remarks"></textarea>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-12">
									<button v-if="edit_remarks" class="btn btn-sm float-end btn-primary mt-2"
										@click="updateUserRemarks()">Update
										Remarks</button>
									<button v-else class="btn btn-sm float-end btn-primary mt-2"
										@click="addUserRemarks()">Add
										Remarks</button>
								</div>
							</div>
							<div class="table-responsive mt-2">
								<table class="table table-sm table-bordered table-striped">
									<thead>
										<tr class="text-center">
											<th class="text-center">SL NO</th>
											<th>LAST UPDATED</th>
											<th>USER</th>
											<th>REMARKS</th>
											<th>ACTIONS</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="remarks.length" v-for="remark, key in remarks" :key="key" class="">
											<td width="10%" class="text-center">{{ key + 1 }}</td>
											<td class="text-center">{{ moment(remark.updated_at) }}
											</td>
											<td>{{ remark.user.name }}</td>
											<td width="50%">{{ remark.remarks }}</td>
											<td class="text-center" width="15%" style="vertical-align: middle;">
												<nav>
													<button v-if="$store.getters.user.user_id == remark.user_id"
														class="btn btn-sm btn-outline-danger me-2"
														@click="deleteUserRemarks(remark.user_remark_id)"><i
															class="ri-chat-delete-line"
															title="Delete Remarks"></i></button>
													<button v-if="$store.getters.user.user_id == remark.user_id"
														class="btn btn-sm btn-outline-primary"
														@click="editRemarks(remark)"><i class="ri-pencil-line"
															title="Edit Remarks"></i></button>
												</nav>
											</td>
										</tr>
										<tr v-else class="">
											<td class="text-center" colspan="5">No records to display!</td>
										</tr>
									</tbody>
								</table>
							</div>

						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						@click="discardRemarks()">Close</button>
					<!-- <button type="button" class="btn btn-primary">Add Remarks</button> -->
				</div>
			</div>
		</div>
	</div>
	<!-- Enlarge image -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="enlargeImageModal">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Rowdy Images</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-2" style="display: flex; align-items: center;">
							<button v-if="this.shift.index > 0" class="btn btn-sm btn-outline-primary float-start"
								@click="shifLeft()"> <i class="ri-arrow-left-line"></i> </button>
						</div>
						<div class="col-8">
							<img class="modal-img" :src="shift.image" alt="" />
						</div>
						<div class="col-2" style="display: flex; align-items: center;">
							<button v-if="shift.index <= rowdy_images.length - 2"
								class="btn btn-sm btn-outline-primary float-end" @click="shiftRight()"> <i
									class="ri-arrow-right-line"></i> </button>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import moment from 'moment';
export default {
	data() {
		return {
			rowdy: {
				rowdy_id: '',
				rowdy_sheeter_name: '',
				age: '',
				fpb_coid_number: '',
				alias: '',
				father_name: '',
				present_address: '',
				permanent_address: '',
				remarks: '',
				aadhaar_number: '',
				mobile_no: ''
			},
			rowdy_categories: [],
			cases: [],
			par_cases: [],
			processes: [],
			goondas: [],
			r_paths: [],
			path: '',
			remarks: [],
			user_remarks: '',
			edit_remarks: false,
			user_remark_id: '',
			rowdy_image: '',
			rowdy_images: [],
			shift: {
				index: 0,
				image: ''
			},
		}
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			if (sessionStorage.getItem('r_paths')) {
				vm.r_paths = JSON.parse(sessionStorage.getItem('r_paths'));
				if (vm.r_paths[0]) {
					vm.path = vm.r_paths[0];
					vm.r_paths[0] = null;
				}
			} else {
				vm.r_paths[0] = from.path;
				vm.path = from.path;
				sessionStorage.setItem('r_paths', JSON.stringify(vm.r_paths));
			}
			vm.getRowdy(to.params.rowdy_id);
		})
	},

	methods: {
		getRowdy(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getRowdy', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.rowdy = response.data.data;
					this.rowdy_categories = this.rowdy.rowdy_categories;
					this.getCases(this.rowdy.rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getCases(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getCases', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.cases = response.data.data;
					this.getProcesses(this.rowdy.rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getProcesses(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getProcesses', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.processes = response.data.data;
					this.getGoondas(this.rowdy.rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getGoondas(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getGoondas', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.goondas = response.data.data;
					this.getParCases(rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getParCases(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getParCases', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.par_cases = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		downloadSheets() {
			window.open(this.$store.getters.downloadUrl + "downloadSheets?rowdy_id=" + this.rowdy.rowdy_id);
		},

		exportRowdiesCasesPdf() {
			window.open(this.$store.getters.downloadUrl + "exportRowdiesCasesPdf?rowdy_id=" + this.rowdy.rowdy_id);
		},

		printViewPdf() {
			window.open(this.$store.getters.downloadUrl + "rowdyViewPdf?rowdy_id=" + this.rowdy.rowdy_id);
		},

		getUserRemarks(rowdy_id) {
			this.rowdy_id = rowdy_id;
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getUserRemarks', data: { rowdy_id: this.rowdy_id } })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		addUserRemarks() {
			if (this.user_remarks == '') {
				alert('Remarks field cannot be empty!');
				return;
			}
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'addUserRemarks', data: { rowdy_id: this.rowdy_id, remarks: this.user_remarks } })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
					this.user_remarks = '';
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		deleteUserRemarks(user_remark_id) {
			if (!confirm('This remark will be deleted permanantly!')) {
				return;
			}
			let vm = this;
			let loader = this.$loading.show();
			let data = { rowdy_id: this.rowdy_id, user_remark_id: user_remark_id };
			this.$store.dispatch('post', { uri: 'deleteUserRemarks', data: data })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
					this.user_remarks = '';
				})
				.catch(error => {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		editRemarks(remark) {
			this.edit_remarks = true;
			this.user_remarks = remark.remarks;
			this.user_remark_id = remark.user_remark_id;
		},

		updateUserRemarks() {
			this.edit_remarks = false;
			let vm = this;
			let data = { rowdy_id: this.rowdy_id, remarks: this.user_remarks, user_remark_id: this.user_remark_id }
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'updateUserRemarks', data: data })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
					this.user_remarks = '';
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		discardRemarks() {
			this.user_remarks = '';
		},

		moment(date) {
			if (date) {
				return moment(date).format('DD-MM-YYYY');
			}
		},

		formatHowLong(obj) {
			let str = '';
			if (obj) {
				str = 'Days: <span class=\"text-primary\">' + obj.days + '</span><br>Months: <span class=\"text-primary\">' + obj.months + '</span><br>Years : <span class=\"text-primary\">' + obj.years + '</span>';
			}
			return str;
		},

		formatCaseUtp(obj) {
			let str = '';
			if (obj) {
				str = 'Station/Crime/No/U/s: <span class=\"text-primary\""<br>' + obj.crime_number + '</span><br>UTP Number: <span class=\"text-primary\"><br>' + obj.utp_number + '</span>';
			}
			return str;
		},

		getRowdyImages(rowdy_id) {
			let vm = this;
			let loader = vm.$loading.show();
			this.$store.dispatch('post', { uri: 'getRowdyImages', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.rowdy_images = response.data.data;
					if (this.rowdy_images.length) {
						this.shift.index = 0;
						this.shift.image = this.rowdy_images[0].image;
					}
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		shifLeft() {
			if (this.rowdy_images.length) {
				this.shift.index--;
				this.shift.image = this.rowdy_images[this.shift.index].image;
			}
		},

		shiftRight() {
			if (this.rowdy_images.length) {
				this.shift.index++;
				this.shift.image = this.rowdy_images[this.shift.index].image;
			}
		},
	}
}
</script>
<style>
mark {
	background: orange;
	color: black;
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: rgb(214, 211, 211);
	border-style: solid;
	border-width: 1px;
	height: 35px !important;
	vertical-align: middle;
}
</style>