<template>
    <div class="page-sign d-block py-0">
        <div class="row g-0">
            <div class="col-md-7 col-lg-5 col-xl-6 col-wrapper">
                <div class="card card-sign">
                    <div class="card-header">
                        <div class="row mb-2 gap-2">
                            <div class="col-2">
                                <img src="assets/img/log11.png" class="img" width="50" height="50" />
                            </div>
                            <div class="col-8">
                                <a href="#" class="text-center h3 header-logo mb-0">Rowdies Gangs</a>
                                <p class="card-text fs-12">Portal for Bengaluru City Police</p>
                            </div>
                        </div>

                        <!-- <img src="assets/img/logo.jpg" class="img" width="100" />
                            <a href="#" class="text-center header-logo mb-3 mt-2">Rowdies Gangs</a> -->

                        <h4 class="card-title1 fw-700 text-dark">Login to your account!</h4>
                        <!-- <p class="card-text">Welcome back! Please signin to continue.</p> -->
                    </div>
                    <div class="card-body">
                        <div class="mb-4">
                            <label class="form-label mb-0">Phone Number</label>
                            <!-- <input type="text" class="form-control border-0 border-bottom " placeholder="Enter your email address"/> -->
                            <div class="input-group border-bottom">
                                <div class="input-group-text border-0  bg-white">+91-</div>
                                <input type="text" @keypress="isNumber($event)" maxlength="10"
                                    class="form-control border-0" :class="{ 'is-invalid': errors.mobile_no }"
                                    id="autoSizingInputGroup" ref="mobile_no" placeholder="O O O O O O O O O O "
                                    v-model="user.mobile_no" :disabled="otp_status" @keydown.enter="sendOtp()" />
                                <div class="input-group-text border-0  bg-white"><img class="img_flag"
                                        src="assets/img/flag.png" width="100" /></div>
                                <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0]
                                    }}</span>
                            </div>
                            <div v-if="!otp_status" class="float-end mb-4 mt-2"><a href="javascript:void(0)"
                                    @click="sendOtp()">REQUEST
                                    OTP</a></div>
                        </div>
                        <div v-if="otp_status">
                            <div class="mb-4">
                                <label class="form-label d-flex justify-content-between mb-0">OTP</label>
                                <div class="inputs d-flex flex-row justify-content-center my-2">
                                    <v-otp-input ref="otpInput"
                                        input-classes="otp-input m-1 text-center form-control inputotp"
                                        :conditionalClass="p - otp" separator="" inputType="letter-numeric"
                                        :num-inputs="6" v-model:value="p_otp1" :should-auto-focus="true"
                                        :should-focus-order="true" @on-change="handleOnChange"
                                        @on-complete="verifyOtp()" />
                                </div>

                                <div class="float-end mb-4"><a href="javascrit:void(0)" @click="resendOtp()"
                                        style="color:#6e7985;"><span><i class="h4 ri-restart-line me-2"
                                                style="color: black;"></i></span>RESEND THE OTP</a></div>
                            </div>
                            <button class="btn btn-primary btn-sign" @click="verifyOtp()">Login To Continue </button>
                        </div>
                    </div>
                    <img src="assets/img/bg3.png" class="img2">
                </div>
            </div>
            <div class="col d-none d-lg-block" style="position: relative;"><img src="assets/img/bg1.png"
                    class="auth-img" alt="" />
                <!-- <p class="kan_text text-white">ದೇವರನ್ನು ಪೂಜಿಸಿದರೆ ದೇವರಾಗುತ್ತೇವೆ, ದೆವ್ವಗಳನ್ನು ಪೂಜಿಸಿದರೆ
                    ದೆವ್ವಗಳಾಗುತ್ತೇವೆ.<br>– ಕುವೆಂಪು, ಆತ್ಮಶ್ರೀಗಾಗಿ ನಿರಂಕುಶಮತಿಗಳಾಗಿ, ಪುಟ ೧೮</p> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Login",
    data() {
        return {
            user: {
                mobile_no: "",
                otp: "",
            },
            errors: [],
            otp_status: false,
            p_otp: {
                first: '',
                second: '',
                third: '',
                fourth: '',
                fifth: '',
                sixth: ''
            },
            p_otp1: ''
        };
    },
    mounted() {
        this.$refs.mobile_no.focus();
    },

    methods: {
        focusInput(e) {
            const input = e.target;
            let value = input.value;
            let isValidInput = value.match(/[0-9a-z]/gi);
            input.value = "";
            input.value = isValidInput ? value[0] : "";
            let fieldIndex = input.dataset.index;
            console.log("fieldIndex", fieldIndex)
            // if (fieldIndex < inputs.length - 1 && isValidInput) {
            //     input.nextElementSibling.focus();
            // }
        },
        sendOtp() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("auth", { uri: "sendOtp", data: vm.user })
                .then(function () {
                    vm.otp_status = true;
                    loader.hide();
                    vm.errors = [];
                    // vm.$refs.otp.focus();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        resendOtp() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("auth", { uri: "resendOtp", data: vm.user })
                .then(function () {
                    vm.status = true;
                    loader.hide();
                    vm.errors = [];
                    // vm.$refs.otp.focus();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        verifyOtp() {
            let vm = this;
            let otp = [this.p_otp.first, this.p_otp.second, this.p_otp.third, this.p_otp.fourth, this.p_otp.fifth, this.p_otp.sixth];
            this.user.otp = otp.join('');
            console.log("otp", vm.p_otp1)
            vm.user.otp = vm.p_otp1
            let loader = vm.$loading.show();
            vm.$store.dispatch("auth", { uri: "verifyOtp", data: vm.user })
                .then(function (response) {
                    loader.hide();
                    vm.otp_status = false
                    vm.$store.dispatch('setPermissions',response.data.permissions);
                    vm.$store.dispatch("setUser", response.data.user);
                    vm.$store.dispatch("setToken", response.data.token);   
                    sessionStorage.setItem("path", "/gang_list");                 
                    // vm.$store.dispatch("setPermissions", response.data.permissions);                    
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        isNumber(e) {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            const keyPressed = e.key;
            if (!keysAllowed.includes(keyPressed)) {
                e.preventDefault()
            }
        },        
    },
};
</script>
<style scoped>
.fw-700 {
    font-weight: 700;
}

.img_flag {
    height: 20px;
    width: 25px;
}

.auth-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: normal !important;
    opacity: inherit !important;
}

.fs-12 {
    font-size: 12px;
}

.img2 {
    opacity: 15%;
    width: 163px;
    height: 122px;
    top: 873px;
    left: 21px;
}

.kan_text {
    position: absolute;
    left: 40px;
    bottom: 8px;
    /* width:498px */
}

.inputotp {
    height: 50px !important;
    width: 50px !important;
    margin: 2px !important;
}

/*.otp-input {
    padding: 5px;
    margin: 2 10px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    height: 50px;
    width: 50px;
  }*/
/* Background colour of an input field with value */
/* .otp-input.is-complete {
    background-color: #e4e4e4;
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    
  }*/
input::placeholder {
    font-size: 15px;
    font-weight: 600;


}

input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: white !important;
    color: fieldtext !important;
}


.card-sign .form-control,
.otp-input {
    font-weight: 500;
    color: #212830;
    height: 50px !important;
    width: 50px;
    margin: 2px;
}

.form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}
</style>
