<template>
	<div class="d-md-flex align-items-center justify-content-between gap-4 mt-2 mb-4">
		<div v-if="update_status">
			<router-link :to="path" class="text-dark m-auto"><i class="ri-arrow-left-line fs-24"></i></router-link>
			<h2 class="main-title fs-28 mb-0" style="display: inline;">Update Rowdy</h2>
		</div>
		<h2 v-else class="main-title fs-28 mb-0">New Rowdy</h2>
		<div v-can="'rowdy.rowdy_list'">
			<router-link :to="'/all_rowdy_list/'"
				class="mt-auto btn btn-outline-primary d-flex align-items-center gap-2">All Rowdy Sheeters</router-link>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<ul class="nav nav-tabs card-header-tabs mb-0" data-bs-tabs="tabs">
						<li class="nav-item">
							<a class="nav-link text-dark fw-semibold fs-16" @click="setTabStatus('rowdy')"
								:class="{ 'active': tab.rowdy }" aria-current="true" data-bs-toggle="tab"
								href="#rowdyDetails">Rowdy Sheeter</a>
						</li>
						<li class="nav-item" :class="{ 'rowdy_disabled': !update_status }" v-show="update_status">
							<a class="nav-link text-dark fw-semibold fs-16" :class="{ 'active': tab.cases }"
								ref="cases_tab" :disabled="!update_status" @click="getCases(rowdy.rowdy_id)"
								data-bs-toggle="tab" href="#caseDetails">Cases</a>
						</li>
						<li class="nav-item" :class="{ 'rowdy_disabled': !update_status }" v-show="update_status">
							<a class="nav-link text-dark fw-semibold fs-16" :class="{ 'active': tab.process }"
								:disabled="!update_status" @click="getProcesses(rowdy.rowdy_id)" data-bs-toggle="tab"
								href="#processDetails">Processes</a>
						</li>
						<li class="nav-item" :class="{ 'rowdy_disabled': !update_status }" v-show="update_status">
							<a class="nav-link text-dark fw-semibold fs-16" :class="{ 'active': tab.goonda }"
								:disabled="!update_status" @click="getGoondas(rowdy.rowdy_id)" data-bs-toggle="tab"
								href="#goondaActDetails">Goonda
								Act / Externment / KCOCA Act</a>
						</li>
						<li class="nav-item" :class="{ 'rowdy_disabled': !update_status }" v-show="update_status">
							<a class="nav-link text-dark fw-semibold fs-16" :class="{ 'active': tab.par }"
								:disabled="!update_status" data-bs-toggle="tab" @click="getParCases(rowdy.rowdy_id)"
								href="#parDetails">PAR</a>
						</li>
					</ul>
					<div class="card-body tab-content">
						<div class="tab-pane" :class="{ 'active': tab.rowdy }" id="rowdyDetails">
							<div class="row">
								<div class="col-sm-3">
									<div class="d-block mt-2 card align-items-start align-items-sm-center">
										<a href="javascript:void(0)" data-bs-toggle="modal"
											data-bs-target="#enlargeImageModal" @click="getRowdyImages(rowdy.rowdy_id)">
											<img :src="rowdy.rowdy_sheeter_photo ? rowdy.rowdy_sheeter_photo : '/assets/img/avatar.jpg'"
												alt="user-avatar" width="100%" id="uploadedAvatar" />
										</a>
										<div class="button-wrapper text-center">
											<label for="upload" class="btn btn-primary me-2 mt-2" tabindex="0">
												<span class="d-none d-sm-block">Upload Primary
													Photo</span>
												<i class="bx bx-upload d-block d-sm-none"></i>
												<input type="file" id="upload" class="account-file-input" hidden
													@change="onImageChange($event)" accept="image/png, image/jpeg"
													:disabled="!can_edit" />
											</label>
										</div>
										<span class="invalid-feedback">{{ }}</span>
										<div class="button-wrapper text-center mb-3">
											<label class="btn btn-primary me-2 mt-2" tabindex="0">
												<span class="d-none d-sm-block">Upload Multiple
													Images</span>
												<input type="file" id="" @change="uploadMultipleImages()"
													class="account-file-input" hidden ref="file"
													accept="image/png, image/jpeg" multiple />
											</label>
										</div>
										<span class="invalid-feedback">{{ }}</span>
									</div>
								</div>
								<div class="col-sm-9">
									<div class="row">
										<div class="col-4 mb-3">
											<label class="form-label">FPB Coid Number</label>
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('fpb_coid_number')"><i
													class="ri-information-2-line"></i></a>
											<input type="text" class="form-control border-0 border-bottom"
												v-model="rowdy.fpb_coid_number"
												:class="{ 'is-invalid': errors?.fpb_coid_number }"
												placeholder="Enter FPB Coid number" :disabled="!can_edit" />
											<span v-if="errors?.fpb_coid_number" class="invalid-feedback">
												{{ errors?.fpb_coid_number[0] }} <a v-if="existing_rowdy" href="#"
													@click.prevent="loadExisitngRowdy(existing_rowdy)">
													Click here to edit the record
												</a> </span>
										</div>
										<div class="col-4 mb-3">
											<label class="form-label">Aadhaar</label>
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('aadhaar_number')"><i
													class="ri-information-2-line"></i></a>
											<input type="text" class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors?.aadhaar_number }"
												@keypress="isNumber($event)" maxlength="12"
												placeholder="Enter aadhaar number" v-model="rowdy.aadhaar_number"
												:disabled="!can_edit" />
											<span v-if="errors?.aadhaar_number" class="invalid-feedback">{{
												errors?.aadhaar_number[0] }}</span>
										</div>
										<div class="col-4 mb-3">
											<label class="form-label">Voter ID</label>
											<!-- <span class="text-danger">*</span> -->
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('voter_card_no')"><i
													class="ri-information-2-line"></i></a>
											<input type="text" class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors?.voter_card_no }" maxlength="15"
												placeholder="Enter voter ID" v-model="rowdy.voter_card_no"
												:disabled="!can_edit" />
											<span v-if="errors?.voter_card_no" class="invalid-feedback">{{
												errors?.voter_card_no[0] }}</span>
										</div>
										<div class="col-12 mb-3">
											<label class="form-label">Rowdy Sheeter Name</label>
											<span class="text-danger"> *</span>
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('rowdy_sheeter_name')"><i
													class="ri-information-2-line"></i></a>
											<input type="text" class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors?.rowdy_sheeter_name }"
												placeholder="Enter rowdy sheeter name"
												v-model="rowdy.rowdy_sheeter_name" :disabled="!can_edit" />
											<span v-if="errors?.rowdy_sheeter_name" class="invalid-feedback">{{
												errors?.rowdy_sheeter_name[0] }}</span>
										</div>
										<div class="col-4 mb-3">
											<label class="form-label">Alias</label>
											<!-- <span class="text-danger">*</span> -->
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('alias')"><i
													class="ri-information-2-line"></i></a>
											<input type="text" class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors?.alias }" placeholder="Enter alias"
												v-model="rowdy.alias" :disabled="!can_edit" />
											<span v-if="errors?.alias" class="invalid-feedback">{{
												errors?.alias[0] }}</span>
										</div>
										<div class="col-4 mb-3">
											<label class="form-label">Father Name</label>
											<!-- <span class="text-danger">*</span> -->
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('father_name')"><i
													class="ri-information-2-line"></i></a>
											<input type="text" class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors?.father_name }"
												placeholder="Enter father name" v-model="rowdy.father_name"
												:disabled="!can_edit" />
											<span v-if="errors?.father_name" class="invalid-feedback">{{
												errors?.father_name[0] }}</span>
										</div>
										<div class="col-4 mb-3">
											<label class="form-label">Birth Date</label>
											<span class="text-danger"> *</span>
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('date_of_birth')"><i
													class="ri-information-2-line"></i></a>
											<input type="date" class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors?.date_of_birth }"
												v-model="rowdy.date_of_birth" :disabled="!can_edit" />
											<span v-if="errors?.date_of_birth" class="invalid-feedback">{{
												errors?.date_of_birth[0] }}</span>
										</div>
										<div class="col-4 mb-3">
											<label class="form-label">Gender</label>
											<!-- <span class="text-danger">*</span> -->
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('gender')"><i
													class="ri-information-2-line"></i></a>
											<select class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors?.gender }" v-model="rowdy.gender"
												:disabled="!can_edit">
												<option value="">Select</option>
												<option value="Male">Male</option>
												<option value="Female">Female</option>
												<option value="Others">Transgender</option>
											</select>
											<span v-if="errors?.gender" class="invalid-feedback">{{
												errors?.gender[0] }}</span>
										</div>
										<div class="col-4 mb-3">
											<label class="form-label">Mobile Number</label>
											<!-- <span class="text-danger">*</span> -->
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('mobile_no')"><i
													class="ri-information-2-line"></i></a>
											<input type="text" @keypress="isNumber($event)" maxlength="10"
												class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors?.mobile_no }"
												placeholder="Enter mobile number" v-model="rowdy.mobile_no"
												:disabled="!can_edit" />
											<span v-if="errors?.mobile_no" class="invalid-feedback">{{
												errors?.mobile_no[0] }}</span>
										</div>
										<div class="col-4">
											<label class="form-label">Locality</label>
											<!-- <span class="text-danger">*</span> -->
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('locality')"><i
													class="ri-information-2-line"></i></a>
											<select class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors?.locality }" v-model="rowdy.locality"
												:disabled="!can_edit">
												<option value="">Select</option>
												<option value="Local (Bengaluru City)">Local (Bengaluru City)</option>
												<option value="Non Local (Other District)">Non Local (Other District)
												</option>
												<option value="Interstate (Out of State)">Interstate (Out of State)
												</option>
												<option value="Out of Country (Foreigner)">Out of Country (Foreigner)
												</option>
											</select>
											<span v-if="errors?.locality" class="invalid-feedback">{{
												errors?.locality[0] }}</span>
										</div>
									</div>
								</div>
								<div class="col-12 mt-4">
									<label class="form-label">Present Address</label>
									<!-- <span class="text-danger">*</span> -->
									<textarea class="form-control border-0 border-bottom"
										:class="{ 'is-invalid': errors?.present_address }"
										v-model="rowdy.present_address" placeholder="Enter present address..." cols="30"
										rows="2" :disabled="!can_edit"></textarea>
									<span v-if="errors?.present_address" class="invalid-feedback">{{
										errors?.present_address[0] }}</span>
								</div>
								<div class="col-12 mt-4">
									<label class="form-label">Permanent Address</label>
									<!-- <span class="text-danger">*</span> -->
									<textarea class="form-control border-0 border-bottom"
										:class="{ 'is-invalid': errors?.permanent_address }"
										v-model="rowdy.permanent_address" placeholder="Enter permanent address..."
										cols="30" rows="2" :disabled="!can_edit"></textarea>
									<span v-if="errors?.permanent_address" class="invalid-feedback">{{
										errors?.permanent_address[0] }}</span>
								</div>
								<div class="col-12 mt-4">
									<label class="form-label">Short note / Remarks</label>
									<!-- <span class="text-danger">*</span> -->
									<textarea class="form-control border-0 border-bottom"
										:class="{ 'is-invalid': errors?.remarks }" v-model="rowdy.remarks"
										placeholder="Enter short note or remarks..." cols="30" rows="2"
										:disabled="!can_edit"></textarea>
									<span v-if="errors?.remarks" class="invalid-feedback">{{
										errors?.remarks[0] }}</span>
								</div>
							</div>
							<div class="card my-4 small" v-can="'category.add'">
								<div class="card-header">
									<span class="h5">Rowdy Category <i v-if="errors.rowdy_categories"
											class="ri-error-warning-line text-danger"></i></span>
								</div>
								<div class="row card-body">
									<div class="col-3">
										<label for="" class="form-label">Open Date</label>
										<input type="date" class="form-control border-0 border-bottom"
											placeholder="Rowdy Openned Date" v-model="rowdy_category.openned_date">
									</div>
									<div class="col-3">
										<label class="form-label">Police Station</label>
										<search class="bord er-0 border-bottom"
											:customClass="{ 'is-invalid': errors?.openned_police_station_id }"
											:class="{ 'is-invalid': errors?.openned_police_station_id }"
											:initialize="openned_police_station_id" id="police_station_id"
											label="police_station" placeholder="Police station" :data="police_stations"
											@selected="assignPoliceStation">
										</search>
										<span v-if="errors?.openned_police_station_id" class="invalid-feedback">{{
											errors?.openned_police_station_id[0] }}</span>
									</div>
									<!-- <div v-if="isOthers" class="col-2">
                                        <label class="form-label">Other Police Station</label>
                                        <input class="form-control border-0 border-bottom"
                                            v-model="rowdy_category.other_police_station" type="text"
                                            :customClass="{ 'is-invalid': errors?.other_police_station }">
                                        <span v-if="errors.other_police_station" class="invalid-feedback">{{
                                            errors.other_police_station[0] }}</span>
                                    </div> -->
									<div class="col-3">
										<label for="" class="form-label">Category</label>
										<select name="" id="" class="form-control border-0 border-bottom form-select"
											:class="{ 'is-invalid': errors?.category }"
											v-model="rowdy_category.category">
											<option value="">Select</option>
											<option value="A">A</option>
											<option value="B">B</option>
											<option value="C">C</option>
										</select>
										<span v-if="errors?.category" class="invalid-feedback">{{
											errors?.category[0] }}</span>
									</div>
									<div class="col-3">
										<label for="" class="form-label">Closed Date</label>
										<input type="date" class="form-control border-0 border-bottom"
											v-model="rowdy_category.closed_date" placeholder="Rowdy Closed Date">
									</div>
									<div class="col-3 mt-3">
										<label class="form-label">Rowdy Status</label>
										<select name="" id="" class="form-control border-0 border-bottom form-select"
											v-model="rowdy_category.rowdy_status">
											<option value="">Select</option>
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
											<option value="Closed">Closed</option>
											<option value="Transfer">Transfer</option>
											<option value="Murdered">Murdered</option>
											<option value="Died">Died</option>
											<option value="Suicide">Suicide</option>
										</select>
									</div>
									<!-- <div class="col-2" :class="{ 'mt-3': isOthers }"> -->
									<div class="col-3 mt-3">
										<label class="form-label">Where Abouts</label>
										<select name="" id="" class="form-control border-0 border-bottom form-select"
											v-model="rowdy_category.where_abouts">
											<option value="">Select</option>
											<option value="Present">Present</option>
											<option value="OV">OV</option>
											<option value="JC">JC</option>
										</select>
									</div>
									<div class="col-4 mt-3" v-if="rowdy_category.where_abouts == 'Present'">
										<label class="form-label">Occupation/Business</label>
										<input type="text" class="form-control border-0 border-bottom"
											v-model="rowdy_category.wa_type.occupation">
									</div>
									<div class="col-4 mt-3" v-if="rowdy_category.where_abouts == 'OV'">
										<label class="form-label">From How Long ?</label>
										<div class="d-flex align-content-between">
											<input type="text" class="form-control border-0 border-bottom mx-1"
												@keypress="isNumber($event)" placeholder="Days"
												v-model="rowdy_category.wa_type.how_long.days" maxlength="2">
											<input type="text" class="form-control border-0 border-bottom mx-1"
												@keypress="isNumber($event)" placeholder="Month"
												v-model="rowdy_category.wa_type.how_long.months" maxlength="2">
											<input type="text" class="form-control border-0 border-bottom mx-1"
												@keypress="isNumber($event)" placeholder="Year"
												v-model="rowdy_category.wa_type.how_long.years" maxlength="4">
										</div>
									</div>
									<div class="col-4 mt-3" v-if="rowdy_category.where_abouts == 'JC'">
										<label class="form-label">In Which case. & UTP Number</label>
										<div class="d-flex align-content-between">
											<input type="text" class="form-control border-0 border-bottom mx-1"
												placeholder="Station/Crime No./U/s"
												v-model="rowdy_category.wa_type.case_utp.crime_number">
											<input type="text" class="form-control border-0 border-bottom mx-1"
												placeholder="UTP Number"
												v-model="rowdy_category.wa_type.case_utp.utp_number">
										</div>
									</div>
									<div class="mt-3"
										:class="{ 'col-2': rowdy_category.where_abouts, 'col-2 offset-4': !rowdy_category.where_abouts }"
										style="display: flex; flex-direction: column; justify-content: flex-end;">
										<button v-if="cat_edit_status" class="btn btn-sm btn-outline-success float-end"
											@click="updateCategory()">UPDATE</button>
										<button v-else class="btn btn-sm btn-outline-primary float-end"
											@click="addRowdyCategory()">ADD</button>
									</div>
									<div class="col-12">
										<div class="table-responsive mt-3">
											<span class="text-danger small" v-if="errors?.rowdy_categories">{{
												errors?.rowdy_categories[0] }}</span>
											<table class="table table-sm table-bordered">
												<thead>
													<tr class="text-center">
														<th v-can="'category.update'">Edit</th>
														<th class="text-center">Sl No.</th>
														<th>Openned Date</th>
														<th>Police Station</th>
														<th>Category</th>
														<th>Closed Date</th>
														<th>Rowdy Status</th>
														<th>Where about?</th>
														<th>Where About<br>Description</th>
														<th v-can="'category.delete'">Delete</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="category, key in rowdy_categories" :key="key"
														:class="{ rowdy_categories: !category.rowdy_category_id }">
														<td class="text-center" v-can="'category.update'">
															<button class="btn btn-sm btn-outline-success mx-2"
																v-if="category.can_edit_category || !category.rowdy_category_id"
																@click="editCategory(category)" title="Edit Category"><i
																	class="ri-pencil-line"></i></button>
														</td>
														<td class="text-center">{{ key + 1 }}</td>
														<td>{{ moment(category.openned_date) }}</td>
														<td v-if="category.police_station.police_station == 'Others'">{{
															category.other_police_station }}</td>
														<td v-else>{{ category.police_station.police_station }}</td>
														<td class="text-center">{{ category.category }}</td>
														<td>{{ moment(category.closed_date) }}</td>
														<td>{{ category.rowdy_status }}</td>
														<td>{{ category.where_abouts }}</td>
														<td v-if="category.where_abouts == 'OV'"
															v-html="formatHowLong(category.wa_type?.how_long)"></td>
														<td v-else-if="category.where_abouts == 'JC'"
															v-html="formatCaseUtp(category.wa_type?.case_utp)"></td>
														<td v-else>{{ category.wa_type.occupation }}</td>
														<td class="text-center" v-can="'category.delete'">
															<button class="btn btn-sm btn-outline-danger"
																v-if="category.can_edit_category || !category.rowdy_category_id"
																@click="removeRowdyCategory(category)"
																title="Delete Category"><i
																	class="ri-delete-bin-line"></i></button>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<button v-if="update_status" class="btn btn-primary float-end"
										@click="updateRowdy()" :disabled="cat_edit_status">Update</button>
									<button v-else class="btn btn-primary float-end" @click="addRowdy()">Submit</button>
								</div>
							</div>
						</div>
						<div v-can="'cases.view'" class="tab-pane" :class="{ 'active': tab.cases }" id="caseDetails">
							<div class="row row-cols-auto g-xl-4 gap-1 mt-2 pt-2">
								<div class="col mt-2 border-right">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										isNaN(rowdy?.count_murder_cases) ? 0 : rowdy?.count_murder_cases }}</h6>
									<span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">Murder</span>
								</div>
								<div class="col mt-2 border-right">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										isNaN(rowdy?.count_attempt_to_murder_cases) ? 0 :
											rowdy?.count_attempt_to_murder_cases }}</h6>
									<span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">Attempt To Murder</span>
								</div>
								<!-- <div class="col mt-2 border-right">
                                    <h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.cases_149 }}</h6>
                                    <span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">149 IPC</span>
                                </div>
                                <div class="col mt-2 border-right">
                                    <h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.cases_420 }}</h6>
                                    <span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">420 IPC</span>
                                </div>
                                <div class="col mt-2 border-right">
                                    <h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.cases_robbery }}
                                    </h6>
                                    <span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">Robbery</span>
                                </div> -->
								<div class="col mt-2 border-right">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										isNaN(totalOthers()) ? 0 : totalOthers() }}
									</h6>
									<span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">Others</span>
								</div>
								<div class="col mt-2">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										isNaN(rowdy?.cases_type_total) ? 0 : rowdy?.cases_type_total
									}}</h6>
									<span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">Total</span>
								</div>
							</div>
							<div class="row">
								<div class="col-md" v-can="'cases.add'">
									<router-link :to="'/rowdy_case_create/' + rowdy.rowdy_id"
										class="btn btn-sm btn-primary float-end">Add Case</router-link>
								</div>
							</div>
							<div class="small mt-2">
								<div class="table-responsive">
									<table class="table table-sm table-bordered table-striped">
										<thead>
											<tr>
												<th v-can="'cases.update'"> Edit</th>
												<th class="text-center">Sl No.</th>
												<th>&nbsp;&nbsp;&nbsp;FIR Date&nbsp;&nbsp;&nbsp;</th>
												<!-- <th>FIR Stage</th> -->
												<th>Police Station</th>
												<th>Crime Number</th>
												<th>Section of Law</th>
												<th class="text-center">Warrant Issued</th>
												<!-- <th>Crime Head</th> -->
												<th>Victim/Deceased</th>
												<th>Accomplices Names</th>
												<th>Case Status</th>
												<th>Court Name</th>
												<th>CC / SC Number</th>
												<th>Next Hearing Date</th>
												<th v-can="'cases.delete'">Delete</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="rowdy_case, key in cases">
												<td class="text-center" v-can="'cases.update'">
													<nav v-if="rowdy_case.can_edit_case">
														<router-link
															:to="'/rowdy_case_update/' + rowdy_case.rowdy_id + '/' + rowdy_case.rowdies_case_id"
															class="text-primary me-2"><i class="ri-edit-line fs-18 lh-1"
																title="Edit rowdy"></i></router-link>
													</nav>
												</td>
												<td class="text-center">{{ key + 1 }}</td>
												<td class="nowrap">{{ moment(rowdy_case.fir_date) }}</td>
												<!-- <td>{{ rowdy_case.fir_stage }}</td> -->
												<td v-if="rowdy_case.police_station?.police_station == 'Others'">{{
													rowdy_case.other_police_station }}</td>
												<td v-else>{{ rowdy_case.police_station?.police_station }}</td>
												<td>{{ rowdy_case.crime_number }}</td>
												<td>{{ rowdy_case.section_of_law }}</td>
												<td class="text-center">{{ rowdy_case.warrant_issued ? 'Yes' : 'No' }}
												</td>
												<!-- <td>{{ rowdy_case.crime_head }}</td> -->
												<td>{{ rowdy_case.victim_deceased }}</td>
												<td>{{ rowdy_case.accomplices_names }}</td>
												<td>{{ rowdy_case.case_status }}</td>
												<td>{{ rowdy_case.court_name }}</td>
												<td>{{ rowdy_case.cc_sc_number }}</td>
												<td>{{ moment(rowdy_case.next_hearing_date) }}</td>
												<td class="text-center" v-can="'cases.delete'">
													<button class="btn btn-sm btn-outline-danger"
														v-if="rowdy_case.can_edit_case"
														@click="deleteRowdyCase(rowdy_case.rowdies_case_id)"><i
															class="ri-delete-bin-line" title="Delete Case"></i>
													</button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div v-can="'process.view'" class="tab-pane" :class="{ 'active': tab.process }"
							id="processDetails">
							<div class="row row-cols-auto g-xl-4 gap-1 mt-2 pt-2">
								<div class="col mt-2 border-right">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										rowdy?.process_notices_count }}</h6>
									<label class="card-title fw-semibold mb-2">Notices</label>
								</div>
								<div class="col mt-2 border-right">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										rowdy?.process_warrants_count }}</h6>
									<label class="card-title fw-semibold mb-2">Warrants</label>
								</div>
								<div class="col mt-2 border-right">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										rowdy?.process_summons_count }}</h6>
									<label class="card-title fw-semibold mb-2">Summons</label>
								</div>
								<div class="col mt-2 border-right">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										rowdy?.process_proclamation_count }}
									</h6>
									<label class="card-title fw-semibold mb-2">Proclamation</label>
								</div>
								<div class="col mt-2">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.process_count }}
									</h6>
									<label class="card-title fw-semibold mb-2">Total</label>
								</div>
							</div>
							<div class="row">
								<div v-can="'process.add'" class="col-md">
									<router-link :to="'/rowdy_process_create/' + rowdy.rowdy_id"
										class="btn btn-sm btn-primary float-end">Add Process</router-link>
								</div>
							</div>
							<div class="small mt-2">
								<div class="table-responsive">
									<table class="table table-sm table-bordered table-striped">
										<thead>
											<tr>
												<th v-can="'process.update'">Edit</th>
												<th class="text-center">Sl No.</th>
												<th>Process Type</th>
												<th>Police Station</th>
												<th>Crime Number</th>
												<th>Section of Law</th>
												<th>Court Name</th>
												<th>CC / SC Number</th>
												<th>Issued Date</th>
												<th>Served/Unserved</th>
												<th>Next Hearing Date</th>
												<th v-can="'process.delete'">Action</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="process, key in processes" v-if="processes.length">
												<td v-can="'process.update'">
													<a href="javascript:void(0)" v-if="process.can_edit_process"
														@click="editProcess(process.process_id)"><i
															class="ri-edit-line fs-18 lh-1" title="Edit rowdy"></i></a>
												</td>
												<td class="text-center">{{ key + 1 }}</td>
												<td>{{ process.process_type }}</td>
												<td>{{ process.police_station?.police_station }}</td>
												<td>{{ process.crime_number }}</td>
												<td>{{ process.section_of_law }}</td>
												<td>{{ process.court_name }}</td>
												<td>{{ process.cc_sc_number }}</td>
												<td>{{ moment(process.issued_date) }}</td>
												<td>{{ process.status }}</td>
												<td>{{ moment(process.next_hearing_date) }}</td>
												<td v-can="'process.delete'" class="text-center"><button
														v-if="process.can_edit_process"
														class="btn btn-sm btn-outline-danger"><i
															@click="deleteProcess(process.process_id)"
															class="ri-delete-bin-line"
															title="Delete Process"></i></button>
												</td>
											</tr>
											<tr v-else>
												<td colspan="11" class="text-center">No records to display!...</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div v-can="'goonda_act.view'" class="tab-pane" :class="{ 'active': tab.goonda }"
							id="goondaActDetails">
							<div class="row row-cols-auto g-xl-4 gap-1  pt-2">
								<div class="col mt-2 border-right">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.goonda_count }}
									</h6>
									<label class="card-title fw-semibold mb-2">Goonda Act</label>
								</div>
								<div class="col mt-2 border-right">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.externment_count
										}}</h6>
									<label class="card-title fw-semibold mb-2">Externment</label>
								</div>
								<div class="col mt-2 border-right">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.kcoca_act_count
										}}</h6>
									<label class="card-title fw-semibold mb-2">KCOCA Act</label>
								</div>
								<div class="col mt-2">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										rowdy?.goonda_externment_count }}</h6>
									<label class="card-title fw-semibold mb-2">Total</label>
								</div>
							</div>
							<div class="row">
								<div v-can="'goonda_act.add'" class="col-md">
									<router-link :to="'/rowdy_goonda_create/' + rowdy.rowdy_id"
										class="btn btn-sm btn-primary float-end">Add Goonda/Externment/KCOCA
										Act</router-link>
								</div>
							</div>
							<div class="small mt-2">
								<div class="table-responsive">
									<table class="table table-sm table-bordered table-striped">
										<thead>
											<tr>
												<th v-can="'goonda_act.update'">Edit</th>
												<th class="text-center">Sl No.</th>
												<th>Type</th>
												<th>Police Station</th>
												<th>Person Name</th>
												<th>Father Name</th>
												<th>Address</th>
												<th>Activities</th>
												<th>Order No</th>
												<th v-if="goonda_act">Approved/Rejected</th>
												<th v-else>Which Place</th>
												<th v-can="'goonda_act.delete'">Action</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="goonda, key in goondas" v-if="goondas.length">
												<td v-can="'goonda_act.update'">
													<a href="javascript:void(0)" v-if="goonda.can_edit_goonda"
														@click="editGoonda(goonda.goonda_id)"><i
															class="ri-edit-line fs-18 lh-1" title="Edit rowdy"></i></a>
												</td>
												<td class="text-center">{{ key + 1 }}</td>
												<td>{{ goonda.type }}</td>
												<td>{{ goonda.police_station.police_station }}</td>
												<td>{{ goonda.person_name }}</td>
												<td>{{ goonda.father_name }}</td>
												<td>{{ goonda.address }}</td>
												<td>{{ goonda.activities }}</td>
												<td>{{ goonda.order_no }}</td>
												<td v-if="goonda_act">{{ goonda.approved_status }}</td>
												<td v-else>{{ goonda.which_place }}</td>
												<td v-can="'goonda_act.delete'" class="text-center"><button
														v-if="goonda.can_edit_goonda"
														class="btn btn-sm btn-outline-danger"><i
															@click="deleteGoonda(goonda.goonda_id)"
															class="ri-delete-bin-line"
															title="Delete goonda"></i></button>
												</td>
											</tr>
											<tr v-else>
												<td colspan="11" class="text-center">No records to display!...</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div v-can="'par_case.view'" class="tab-pane" :class="{ 'active': tab.par }" id="parDetails">
							<div class="row row-cols-auto g-xl-4 gap-1 mt-2 pt-2">
								<div class="col mt-2 ">
									<h6 class="card-value fs-30 mb-1 justify-content-center">
										{{ rowdy?.par_cases_count }}
									</h6>
									<label class="card-title fw-semibold mb-2">PAR Cases</label>
								</div>
							</div>
							<div class="row">
								<div v-can="'par_case.add'" class="col-md">
									<router-link :to="'/rowdy_par_create/' + rowdy.rowdy_id"
										class="btn btn-sm btn-primary float-end">Add PAR</router-link>
								</div>
							</div>
							<div class="small mt-2">
								<div class="table-responsive">
									<table class="table table-sm table-bordered table-striped">
										<thead>
											<tr class="text-center">
												<th v-can="'par_case.update'" rowspan="2">Edit</th>
												<th rowspan="2" class="text-center">Sl No.</th>
												<th rowspan="2">Police Station</th>
												<th rowspan="2">PAR No.</th>
												<th rowspan="2">Year</th>
												<th rowspan="2">Section<br>Of Law</th>
												<th rowspan="2">PAR Date</th>
												<th colspan="2">Bound Over Date</th>
												<th rowspan="2">Surety Details</th>
												<th rowspan="2">If Violated</th>
												<th rowspan="2">Date</th>
												<th v-can="'par_case.delete'" rowspan="2">Action</th>
											</tr>
											<tr class="text-center">
												<th>From</th>
												<th>To</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="par_case, key in par_cases" v-if="par_cases.length">
												<td v-can="'par_case.update'" class="text-center">
													<nav v-if="par_case.can_edit_par_case">
														<router-link
															:to="'/rowdy_par_update/' + par_case.rowdy_id + '/' + par_case.par_case_id"
															class="text-primary me-2"><i class="ri-edit-line fs-18 lh-1"
																title="Edit PAR case"></i></router-link>
													</nav>
												</td>
												<td class="text-center">{{ key + 1 }}</td>
												<td>{{ par_case.police_station?.police_station }}</td>
												<td>{{ par_case.par_no }}</td>
												<td>{{ par_case.year }}</td>
												<td>{{ par_case.section_of_law }}</td>
												<td>{{ moment(par_case.par_date) }}</td>
												<td>{{ moment(par_case.bound_over_from) }}</td>
												<td>{{ moment(par_case.bound_over_to) }}</td>
												<td>{{ par_case.surety_details }}</td>
												<td>{{ par_case.if_violated }}</td>
												<td>{{ moment(par_case.violated_date) }}</td>
												<td v-can="'par_case.delete'" class="text-center"><button
														v-if="par_case.can_edit_par_case"
														class="btn btn-sm btn-outline-danger"
														@click="deleteParCase(par_case.par_case_id)"><i
															class="ri-delete-bin-line"
															title="Delete PAR case"></i></button>
												</td>
											</tr>
											<tr v-else>
												<td colspan="13" class="text-center">No records to display!...</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- History information modal -->
	<div class="modal fade bd-example-modal-md" tabindex="-1" id="infoModal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Edited History</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-sm table-bordered table-striped">
							<thead>
								<tr>
									<th>#</th>
									<th>NEW {{ edited_field_name }}</th>
									<th>OLD {{ edited_field_name }}</th>
									<th>Edited By</th>
									<th>Date Time</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="edited_history.length" v-for="edited, key in edited_history" :key="key">
									<td>{{ key + 1 }}</td>
									<td v-if="key > 0">{{ edited_history[key - 1].field_value }}</td>
									<td v-else>{{ new_record.field_value }}</td>
									<td>{{ edited.field_value }}</td>
									<td>{{ edited.edited_by.name }}</td>
									<td>{{ edited.edited_date_time }}</td>
								</tr>
								<tr v-else>
									<td colspan="5" class="text-center">No records to display!...</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!-- Enlarge image -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="enlargeImageModal">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Rowdy Images</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-2" style="display: flex; align-items: center;">
							<button v-if="this.shift.index > 0" class="btn btn-sm btn-outline-primary float-start"
								@click="shifLeft()"> <i class="ri-arrow-left-line"></i> </button>
						</div>
						<div class="col-8">
							<img class="modal-img" :src="shift.image" alt="" />
						</div>
						<div class="col-2" style="display: flex; align-items: center;">
							<button v-if="shift.index <= rowdy_images.length - 2"
								class="btn btn-sm btn-outline-primary float-end" @click="shiftRight()"> <i
									class="ri-arrow-right-line"></i> </button>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
let Search = require("@/components/Search.vue").default;
export default {
	components: {
		Search
	},

	data() {
		return {
			rowdy: {
				rowdy_id: '',
				rowdy_sheeter_name: '',
				rowdy_sheet_openned_date: '',
				rowdy_sheet_openned_order_copy: '',
				date_of_entry: '',
				closed_transfer_died_details: '',
				regarded_order_copy_file: '',
				rowdy_sheet_closed_date: '',
				rowdy_sheet_closed_order_copy: '',
				is_active: '',
				// is_approved: '',
				fpb_coid_number: '',
				category: '',
				where_abouts: '',
				police_station_id: '',
				police_station: '',
				alias: '',
				father_name: '',
				present_address: '',
				permanent_address: '',
				fathers_address: '',
				mothers_address: '',
				date_of_birth: '',
				place_of_birth: '',
				height: '',
				gender: '',
				marital_status: '',
				educational_qualification: '',
				school_college: '',
				locality: '',
				police_station_limit: '',
				mobile_no: '',
				details_of_mobile: '',
				aadhaar_number: '',
				passport_no: '',
				voter_card_no: '',
				voter_card_details: '',
				pan_number: '',
				ration_card_number: '',
				driving_licence_details: '',
				native: '',
				present_occupation_details: '',
				previous_occupation_details: '',
				frequently_dialed_contact: '',
				social_media_accounts: '',
				visited_website_details: '',
				languages_known: '',
				caste_religion_nationality: '',
				speech_mother_tongue: '',
				tatoo_mark: '',
				visible_identification_marks: '',
				habits: '',
				rowdy_status: '',
				frequently_visited_places: '',
				rowdy_background: '',
				rowdy_present_activities: '',
				voice_sample: '',
				video_sample: '',
				rowdy_photo_1: '',
				rowdy_photo_2: '',
				rowdy_photo_3: '',
				spouse_details: '',
				spouse_natvie_place: '',
				children_details: '',
				children_occupation_details: '',
				father_in_law_details: '',
				brother_details: '',
				sister_details: '',
				brother_in_law_details: '',
				emotionally_attached_person_details: '',
				friend_details: '',
				bank_account_details: '',
				vehicle_owned_details: '',
				vehicles_used_for_offence: '',
				movable_immovable_property_details: '',
				investment_details: '',
				locker_details: '',
				financer_details: '',
				tailor_details: '',
				garage_details: '',
				motor_mechanic_details: '',
				political_affiliation_details: '',
				influencer_details: '',
				barber_details: '',
				doctor_clinic_frequently_visited: '',
				remarks: ''
			},
			existing_rowdy: {},
			police_stations: [],
			update_status: false,
			image: '',
			errors: [],
			edited_history: [],
			edited_field_name: '',
			cases: [],
			processes: [],
			goondas: [],
			goonda_act: false,
			ipc_cases: [],
			openned_police_station_id: '',
			rowdy_category: {
				openned_date: '',
				police_station_id: '',
				police_station: '',
				other_police_station: '',
				category: '',
				closed_date: '',
				where_abouts: '',
				rowdy_status: '',
				wa_type: {
					occupation: '',
					how_long: {
						days: '',
						months: '',
						years: ''
					},
					case_utp: {
						crime_number: '',
						utp_number: ''
					}
				},
			},
			rowdy_cat_index: '',
			rowdy_categories: [],
			deleted_categories: [],
			path: '',
			paths: [],
			tab: {
				rowdy_id: '',
				rowdy: true,
				cases: false,
				process: false,
				goonda: false,
				par: false
			},
			cat_edit_status: false,
			par_cases: [],
			can_edit: true,
			base_url: 'https://rs.patterneffects.in/#/',
			rowdy_image: '',
			rowdy_images: [],
			shift: {
				index: 0,
				image: ''
			},
		}
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			// vm.path = from.path;
			vm.can_edit = true;
			vm.base_url = window.location.origin + '/#/';
			vm.getPoliceStations();
			if (to.name == 'CreateRowdy') {
				vm.setTabStatus('rowdy');
				sessionStorage.setItem('tab_status', JSON.stringify(vm.tab));
			}
			if (to.name == 'UpdateCases') {
				vm.update_status = true;
				vm.getRowdy(to.params.rowdy_id);
				if (from.name == 'AllRowdyList') {
					vm.setTabStatus('rowdy');
					sessionStorage.setItem('tab_status', JSON.stringify(vm.tab));
				}
			}
			if (from.name == 'UpdateCases' && to.name == 'CreateRowdy') {
				vm.update_status = false;
				for (let key in vm.rowdy) {
					if (key != 'rowdy_id') {
						vm.rowdy[key] = '';
					}
				}
				vm.rowdy_categories = [];
			}
			if (sessionStorage.getItem('paths')) {
				vm.paths = JSON.parse(sessionStorage.getItem('paths'));
				if (vm.paths[0]) {
					vm.path = vm.paths[0];
					vm.paths[0] = null;
				}
			} else {
				vm.paths[0] = from.path;
				vm.path = from.path;
				sessionStorage.setItem('paths', JSON.stringify(vm.paths));
			}
		})
	},

	mounted() {
		if (sessionStorage.getItem('tab_status')) {
			this.tab = JSON.parse(sessionStorage.getItem('tab_status'));
			sessionStorage.removeItem('tab_status');
		}
		if (sessionStorage.getItem('rowdy_id')) {
			this.rowdy.rowdy_id = sessionStorage.getItem('rowdy_id');
			sessionStorage.removeItem('rowdy_id');
		}
		if (this.tab.rowdy) {
			this.setTabStatus('rowdy');
		}
		if (this.tab.cases) {
			this.getCases(this.rowdy.rowdy_id);
		}
		if (this.tab.process) {
			this.getProcesses(this.rowdy.rowdy_id);
		}
		if (this.tab.goonda) {
			this.getGoondas(this.rowdy.rowdy_id);
		}
		if (this.tab.par) {
			this.getParCases(this.rowdy.rowdy_id);
		}
	},

	watch: {
		"rowdy_category.wa_type.how_long.days": function () {
			if (parseInt(this.rowdy_category.wa_type.how_long.days) > 31) {
				alert('Valid range for this field is between 0 and 31.');
				this.rowdy_category.wa_type.how_long.days = '';
			}
		},

		"rowdy_category.wa_type.how_long.months": function () {
			if (parseInt(this.rowdy_category.wa_type.how_long.months) > 12) {
				alert('Valid range for this field is between 0 and 12.');
				this.rowdy_category.wa_type.how_long.months = '';
			}
		},

		"rowdy_category.wa_type.how_long.years": function () {
			if (parseInt(this.rowdy_category.wa_type.how_long.years) > 100) {
				alert('Valid range for this field is between 0 and 100.');
				this.rowdy_category.wa_type.how_long.years = '';
			}
		}
	},

	unmounted() {
		sessionStorage.setItem('tab_status', JSON.stringify(this.tab));
		sessionStorage.setItem('rowdy_id', this.rowdy.rowdy_id);
	},

	computed: {
		isOthers() {
			let police_station = this.police_stations.find(ele => {
				return ele.police_station_id == this.openned_police_station_id;
			})

			if (police_station) {
				if (police_station.police_station == 'Others') {
					return true;
				} else {
					return false;
				}
			}
		}
	},
	methods: {
		getPoliceStations() {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getPoliceStations' })
				.then(response => {
					loader.hide();
					let temp = response.data.data;
					this.police_stations = temp.filter(ele => ele.police_station != 'Others');
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		addRowdy() {
			let vm = this;
			// this.rowdy.rowdy_categories = this.rowdy_categories;

			//start of new code     
			let data = new FormData();
			data.append('fpb_coid_number', this.rowdy.fpb_coid_number);
			data.append('aadhaar_number', this.rowdy.aadhaar_number);
			data.append('voter_card_no', this.rowdy.voter_card_no);
			data.append('rowdy_sheeter_name', this.rowdy.rowdy_sheeter_name);
			data.append('alias', this.rowdy.alias);
			data.append('father_name', this.rowdy.father_name);
			data.append('date_of_birth', this.rowdy.date_of_birth);
			data.append('gender', this.rowdy.gender);
			data.append('mobile_no', this.rowdy.mobile_no);
			data.append('locality', this.rowdy.locality);
			data.append('present_address', this.rowdy.present_address);
			data.append('permanent_address', this.rowdy.permanent_address);
			data.append('remarks', this.rowdy.remarks);

			this.existing_rowdy = {};

			if (this.image != 'avatar.jpg' || this.image != '') {
				data.append('rowdy_sheeter_image', this.image);
			}
			if (this.rowdy_categories.length > 0) {
				data.append('rowdy_categories', JSON.stringify(this.rowdy_categories));
			}
			//end of new code

			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'addRowdy', data: data })
				.then(response => {
					loader.hide();
					this.rowdy = response.data.data;
					if (sessionStorage.getItem('rowdy_list')) {
						let rowdy_list = JSON.parse(sessionStorage.getItem('rowdy_list'));
						rowdy_list.push(this.rowdy);
						sessionStorage.setItem('rowdy_list', JSON.stringify(rowdy_list));
					}
					this.$router.push('/update_cases_processes/' + this.rowdy.rowdy_id);
					for (let key in this.rowdy) {
						if (this.rowdy[key] == null) {
							this.rowdy[key] = '';
						}
					}
					this.errors = [];
					this.updateRowdyCategories();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.existing_rowdy = error.response.data?.rowdy;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		updateRowdy() {
			let vm = this;
			let data = new FormData();
			data.append('rowdy_id', this.rowdy.rowdy_id);
			data.append('rowdy_sheeter_name', this.rowdy.rowdy_sheeter_name);
			if (this.rowdy.rowdy_sheet_openned_date) {
				data.append('rowdy_sheet_openned_date', this.rowdy.rowdy_sheet_openned_date);
			}
			data.append('rowdy_sheet_openned_order_copy', this.rowdy.rowdy_sheet_openned_order_copy);
			if (this.rowdy.date_of_entry) {
				data.append('date_of_entry', this.rowdy.date_of_entry);
			}
			data.append('closed_transfer_died_details', this.rowdy.closed_transfer_died_details);
			data.append('regarded_order_copy_file', this.rowdy.regarded_order_copy_file);
			if (this.rowdy.rowdy_sheet_closed_date) {
				data.append('rowdy_sheet_closed_date', this.rowdy.rowdy_sheet_closed_date);
			}
			data.append('rowdy_sheet_closed_order_copy', this.rowdy.rowdy_sheet_closed_order_copy);
			data.append('is_active', this.rowdy.is_active);
			if (this.rowdy.is_approved) {
				data.append('is_approved', this.rowdy.is_approved);
			}
			data.append('fpb_coid_number', this.rowdy.fpb_coid_number);
			data.append('category', this.rowdy.category);
			data.append('where_abouts', this.rowdy.where_abouts);
			data.append('police_station_id', this.rowdy.police_station_id);
			data.append('police_station', this.rowdy.police_station);
			data.append('alias', this.rowdy.alias);
			data.append('father_name', this.rowdy.father_name);
			data.append('present_address', this.rowdy.present_address);
			data.append('permanent_address', this.rowdy.permanent_address);
			data.append('fathers_address', this.rowdy.fathers_address);
			data.append('mothers_address', this.rowdy.mothers_address);
			data.append('date_of_birth', this.rowdy.date_of_birth);
			data.append('place_of_birth', this.rowdy.place_of_birth);
			data.append('height', this.rowdy.height);
			data.append('gender', this.rowdy.gender);
			data.append('marital_status', this.rowdy.marital_status);
			data.append('educational_qualification', this.rowdy.educational_qualification);
			data.append('school_college', this.rowdy.school_college);
			data.append('locality', this.rowdy.locality);
			data.append('police_station_limit', this.rowdy.police_station_limit);
			data.append('mobile_no', this.rowdy.mobile_no);
			data.append('details_of_mobile', this.rowdy.details_of_mobile);
			data.append('aadhaar_number', this.rowdy.aadhaar_number);
			data.append('passport_no', this.rowdy.passport_no);
			data.append('voter_card_no', this.rowdy.voter_card_no);
			data.append('voter_card_details', this.rowdy.voter_card_details);
			data.append('pan_number', this.rowdy.pan_number);
			data.append('ration_card_number', this.rowdy.ration_card_number);
			data.append('driving_licence_details', this.rowdy.driving_licence_details);
			data.append('native', this.rowdy.native);
			data.append('present_occupation_details', this.rowdy.present_occupation_details);
			data.append('previous_occupation_details', this.rowdy.previous_occupation_details);
			data.append('frequently_dialed_contact', this.rowdy.frequently_dialed_contact);
			data.append('social_media_accounts', this.rowdy.social_media_accounts);
			data.append('visited_website_details', this.rowdy.visited_website_details);
			data.append('languages_known', this.rowdy.languages_known);
			data.append('caste_religion_nationality', this.rowdy.caste_religion_nationality);
			data.append('speech_mother_tongue', this.rowdy.speech_mother_tongue);
			data.append('tatoo_mark', this.rowdy.tatoo_mark);
			data.append('visible_identification_marks', this.rowdy.visible_identification_marks);
			data.append('habits', this.rowdy.habits);
			data.append('rowdy_status', this.rowdy.rowdy_status);
			data.append('frequently_visited_places', this.rowdy.frequently_visited_places);
			data.append('rowdy_background', this.rowdy.rowdy_background);
			data.append('rowdy_present_activities', this.rowdy.rowdy_present_activities);
			data.append('voice_sample', this.rowdy.voice_sample);
			data.append('video_sample', this.rowdy.video_sample);
			data.append('rowdy_photo_1', this.rowdy.rowdy_photo_1);
			data.append('rowdy_photo_2', this.rowdy.rowdy_photo_2);
			data.append('rowdy_photo_3', this.rowdy.rowdy_photo_3);
			data.append('spouse_details', this.rowdy.spouse_details);
			data.append('spouse_natvie_place', this.rowdy.spouse_natvie_place);
			data.append('children_details', this.rowdy.children_details);
			data.append('children_occupation_details', this.rowdy.children_occupation_details);
			data.append('father_in_law_details', this.rowdy.father_in_law_details);
			data.append('brother_details', this.rowdy.brother_details);
			data.append('sister_details', this.rowdy.sister_details);
			data.append('brother_in_law_details', this.rowdy.brother_in_law_details);
			data.append('emotionally_attached_person_details', this.rowdy.emotionally_attached_person_details);
			data.append('friend_details', this.rowdy.friend_details);
			data.append('bank_account_details', this.rowdy.bank_account_details);
			data.append('vehicle_owned_details', this.rowdy.vehicle_owned_details);
			data.append('vehicles_used_for_offence', this.rowdy.vehicles_used_for_offence);
			data.append('movable_immovable_property_details', this.rowdy.movable_immovable_property_details);
			data.append('investment_details', this.rowdy.investment_details);
			data.append('locker_details', this.rowdy.locker_details);
			data.append('financer_details', this.rowdy.financer_details);
			data.append('tailor_details', this.rowdy.tailor_details);
			data.append('garage_details', this.rowdy.garage_details);
			data.append('motor_mechanic_details', this.rowdy.motor_mechanic_details);
			data.append('political_affiliation_details', this.rowdy.political_affiliation_details);
			data.append('influencer_details', this.rowdy.influencer_details);
			data.append('barber_details', this.rowdy.barber_details);
			data.append('doctor_clinic_frequently_visited', this.rowdy.doctor_clinic_frequently_visited);
			data.append('remarks', this.rowdy.remarks);
			if (this.image != 'avatar.jpg' || this.image != '') {
				data.append('rowdy_sheeter_image', this.image);
			}
			if (this.rowdy_categories.length > 0) {
				data.append('rowdy_categories', JSON.stringify(this.rowdy_categories));
			}
			this.existing_rowdy = {}
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'updateRowdy', data: data })
				.then(response => {
					loader.hide();
					// this.rowdy = response.data.rowdy;
					this.$store.dispatch('success', response.data.message);
					// vm.$router.push('/update_rowdy/' + this.rowdy.rowdy_id);
					// for (let key in this.rowdy) {
					//     if (this.rowdy[key] == null) {
					//         this.rowdy[key] = '';
					//     }
					// }
					this.errors = [];
					this.updateRowdyCategories();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.existing_rowdy = error.response.data?.rowdy;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		updateRowdyCategories() {
			let vm = this;
			let loader = this.$loading.show();
			let temp = this.rowdy_categories.map(ele => {
				ele.wa_type = JSON.stringify(ele.wa_type);
				return ele;
			})
			let data = {
				rowdy_id: this.rowdy.rowdy_id,
				rowdy_categories: temp,
				deleted_categories: this.deleted_categories
			}
			this.$store.dispatch('post', { uri: 'updateRowdyCategories', data: data })
				.then(response => {
					loader.hide();
					this.clearCategoryFields();
					this.getRowdy(this.rowdy.rowdy_id);
					this.$router.push('/update_cases_processes/' + this.rowdy.rowdy_id);
					this.updateRowdyList();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getRowdy(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getRowdy', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.rowdy = response.data.data;
					if (this.rowdy.can_edit) {
						this.can_edit = true;
					} else {
						this.can_edit = false;
					}
					for (let key in this.rowdy) {
						if (this.rowdy[key] == null) {
							this.rowdy[key] = '';
						}
					}
					this.rowdy_categories = this.rowdy.rowdy_categories;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		onImageChange(e) {
			let vm = this;
			vm.image = e.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(vm.image);
			reader.onload = (e) => {
				vm.rowdy.rowdy_sheeter_photo = e.target.result;
			};
		},

		getRowdyEditHistory(field_name) {
			this.edited_field_name = field_name;
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getRowdyEditHistory', data: { rowdy_id: this.rowdy.rowdy_id } })
				.then(response => {
					loader.hide();
					this.new_record = response.data.new;
					this.new_record.field_value = this.new_record[field_name];
					this.edited_history = response.data.history;
					let temp = this.edited_history.filter(ele => {
						ele.field_value = ele[field_name];
						return ele[field_name] != null;
					});
					this.edited_history = temp;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},
		addRowdiesCase() {
			let vm = this;
			this.rowdy_case.police_station_id = this.police_station_id;
			if (this.rowdy_case.case_type == '') {
				this.rowdy_case.case_type = 'Others';
			}
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'addRowdiesCase', data: this.rowdy_case })
				.then(response => {
					loader.hide();
					this.$store.dispatch('success', response.data.message);
					this.$router.push('/view_rowdy/' + this.rowdy_case.rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		updateRowdiesCase() {
			let vm = this;
			this.rowdy_case.police_station_id = this.police_station_id;
			if (this.rowdy_case.case_type == null) {
				this.rowdy_case.case_type = 'Others';
			}
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'updateRowdiesCase', data: this.rowdy_case })
				.then(response => {
					loader.hide();
					this.$store.dispatch('success', response.data.message);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		setTabStatus(tab) {
			this.tab.rowdy = false;
			this.tab.cases = false;
			this.tab.process = false;
			this.tab.goonda = false;
			this.tab.par = false;
			switch (tab) {
				case 'rowdy': this.tab.rowdy = true;
					break;
				case 'cases': this.tab.cases = true;
					break;
				case 'process': this.tab.process = true;
					break;
				case 'goonda': this.tab.goonda = true;
					break;
				case 'par': this.tab.par = true;
					break;
			}
		},

		getCases(rowdy_id) {
			this.setTabStatus('cases');
			if (!this.update_status) return;
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getCases', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.cases = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getParCases(rowdy_id) {
			this.setTabStatus('par');
			if (!this.update_status) return;
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getParCases', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.par_cases = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		deleteParCase(par_case_id) {
			if (!confirm('The par case will be deleted permanently!')) { return; }
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'deleteParCase', data: { par_case_id: par_case_id } })
				.then(response => {
					loader.hide();
					this.getParCases(this.rowdy.rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getProcesses(rowdy_id, process_type) {
			this.setTabStatus('process');
			if (!this.update_status) return;
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getProcesses', data: { rowdy_id: rowdy_id, process_type: process_type } })
				.then(response => {
					loader.hide();
					this.processes = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getGoondas(rowdy_id, type) {
			this.setTabStatus('goonda');
			if (!this.update_status) return;
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getGoondas', data: { rowdy_id: rowdy_id, type: type } })
				.then(response => {
					loader.hide();
					this.goondas = response.data.data;
					if (this.goondas.length) {
						if (this.goondas[0].type == 'Goonda') {
							this.goonda_act = true;
						} else {
							this.goonda_act = false;
						}
					}
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		addRowdyCategory() {
			let flag = false;
			this.errors = [];
			let police_station = this.police_stations.find(ele => { return ele.police_station_id == this.openned_police_station_id });
			if (police_station) {
				let temp = this.rowdy_categories.find(ele => { return ele.police_station_id == police_station.police_station_id });
				if (temp) {
					if (temp.category != this.rowdy_category.category) {
						alert('Different categories in same police station!...');
						return;
					}
				}
				this.rowdy_category.police_station = police_station;
				// if (police_station.police_station == 'Others') {
				//     this.rowdy_category.police_station = this.rowdy_category.other_police_station;
				// } else {
				//     this.rowdy_category.police_station = police_station;
				// }
				this.rowdy_category.police_station_id = this.openned_police_station_id;
			} else {
				alert('Please select police station!');
				this.errors.openned_police_station_id = ['Police station field is required.'];
				flag = true;
			}
			if (this.rowdy_category.category == 'A') {
				let temp = this.rowdy_categories.find(ele => { return ele.category == 'A' });
				if (temp) {
					alert('Category A is already selected!');
					return;
				}
			}
			if (this.rowdy_category.category == '') {
				alert('Please select category.');
				this.errors.category = ['Category field is required.'];
				flag = true;
			}
			if (flag) return;
			let obj = Object.assign({}, this.rowdy_category);
			this.rowdy_categories.push(obj);
		},

		removeRowdyCategory(category) {
			if (category.rowdy_category_id) {
				this.deleted_categories.push(category.rowdy_category_id);
			}
			this.rowdy_categories = this.rowdy_categories.filter(ele => ele != category);
		},

		isNumber(e) {
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
			const keyPressed = e.key;
			if (!keysAllowed.includes(keyPressed)) {
				e.preventDefault()
			}
		},

		moment(date) {
			if (date) {
				return moment(date).format('DD-MM-YYYY');
			}
		},

		clearCategoryFields() {
			this.rowdy_category.openned_date = '',
				this.openned_police_station_id = '',
				this.rowdy_category.police_station = '',
				this.rowdy_category.other_police_station = '',
				this.rowdy_category.category = '',
				this.rowdy_category.closed_date = '',
				this.rowdy_category.where_abouts = '',
				this.rowdy_category.rowdy_status = ''
		},

		uploadMultipleImages() {
			let image_data = new FormData();
			image_data.append('rowdy_id', this.rowdy.rowdy_id);
			for (var i = 0; i < this.$refs.file.files.length; i++) {
				let file = this.$refs.file.files[i];
				image_data.append('images[' + i + ']', file);
			}

			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'uploadMultipleImages', data: image_data })
				.then(response => {
					loader.hide();
					this.$store.dispatch('success', response.data.message);
					this.$router.go();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		deleteRowdyCase(rowdy_case_id) {
			if (!confirm('Rowdy case will be deleted permanently!')) {
				return;
			}
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'deleteRowdyCase', data: { rowdies_case_id: rowdy_case_id } })
				.then(response => {
					loader.hide();
					this.$store.dispatch('success', response.data.message);
					this.getCases(this.rowdy.rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		editCategory(rowdy_category) {
			this.cat_edit_status = true;
			this.rowdy_category = rowdy_category;
			this.rowdy_cat_index = this.rowdy_categories.indexOf(rowdy_category);
			this.openned_police_station_id = rowdy_category.police_station_id;
		},

		updateCategory() {
			this.cat_edit_status = false;
			this.rowdy_categories[this.rowdy_cat_index] = Object.assign({}, this.rowdy_category);
			for (var key in this.rowdy_category) {
				if (key != 'wa_type') {
					this.rowdy_category[key] = '';
				}
			}
			this.openned_police_station_id = '';
		},
		assignPoliceStation(police_station_id) {
			this.openned_police_station_id = police_station_id;
			this.rowdy_category.police_station_id = police_station_id;
			this.rowdy_category.police_station = this.police_stations.find(ele => { return ele.police_station_id == this.rowdy_category.police_station_id });
		},

		deleteProcess(process_id) {
			if (!confirm('Process will be deleted permanently!')) { return; }
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'deleteProcess', data: { process_id: process_id } })
				.then(response => {
					loader.hide();
					this.$store.dispatch('success', response.data.message);
					this.getProcesses(this.rowdy.rowdy_id);
					this.getRowdy(this.rowdy.rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		deleteGoonda(goonda_id) {
			if (!confirm('Record will be deleted permanently!')) { return; }
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'deleteGoonda', data: { goonda_id: goonda_id } })
				.then(response => {
					loader.hide();
					this.$store.dispatch('success', response.data.message);
					this.getRowdy(this.rowdy.rowdy_id);
					this.getGoondas(this.rowdy.rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		updateRowdyList() {
			this.getRowdy(this.rowdy.rowdy_id);
			if (sessionStorage.getItem('rowdy_list')) {
				let rowdy_list = JSON.parse(sessionStorage.getItem('rowdy_list'));
				let new_list = rowdy_list.map(ele => {
					return ele.rowdy_id !== this.rowdy.rowdy_id ? ele : this.rowdy;
				});
				sessionStorage.removeItem('rowdy_list');
				sessionStorage.setItem('rowdy_list', JSON.stringify(new_list));
			}
		},

		editProcess(process_id) {
			this.$router.push('/rowdy_process_update/' + this.rowdy.rowdy_id + '/' + process_id);
		},

		editGoonda(goonda_id) {
			this.$router.push('/rowdy_goonda_update/' + this.rowdy.rowdy_id + '/' + goonda_id);
		},

		totalOthers() {
			let total = this.rowdy.others + this.rowdy.cases_149 + this.rowdy.cases_420 + this.rowdy.cases_robbery;
			return total;
		},

		formatHowLong(obj) {
			let str = '';
			if (obj) {
				str = 'Days: <span class=\"text-primary\">' + obj.days + '</span><br>Months: <span class=\"text-primary\">' + obj.months + '</span><br>Years : <span class=\"text-primary\">' + obj.years + '</span>';
			}
			return str;
		},

		formatCaseUtp(obj) {
			let str = '';
			if (obj) {
				str = 'Station/Crime/No/U/s: <span class=\"text-primary\""<br>' + obj.crime_number + '</span><br>UTP Number: <span class=\"text-primary\"><br>' + obj.utp_number + '</span>';
			}
			return str;
		},

		loadExisitngRowdy(rowdy) {
			this.$router.push('/update_cases_processes/' + rowdy.rowdy_id)
				.then(() => {
					location.reload()
				});
		},

		getRowdyImages(rowdy_id) {
			let vm = this;
			let loader = vm.$loading.show();
			this.$store.dispatch('post', { uri: 'getRowdyImages', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.rowdy_images = response.data.data;
					if (this.rowdy_images.length) {
						this.shift.index = 0;
						this.shift.image = this.rowdy_images[0].image;
					}
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		shifLeft() {
			if (this.rowdy_images.length) {
				this.shift.index--;
				this.shift.image = this.rowdy_images[this.shift.index].image;
			}
		},

		shiftRight() {
			if (this.rowdy_images.length) {
				this.shift.index++;
				this.shift.image = this.rowdy_images[this.shift.index].image;
			}
		},
	}
}
</script>

<style scoped>
.modal-img {
	width: -webkit-fill-available;
}

.modal-close {
	background-color: white;
	border-radius: 50%;
	padding: 5px;
}

.rowdy_categories {
	color: blue;
}

.rowdy_disabled {
	color: darkgray;
	background-color: lightgray;
}
</style>
