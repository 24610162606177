<template>
	<div class="d-md-flex align-items-center justify-content-between gap-4 mt-2 mb-4">
		<div>
			<h2 class="main-title fs-28 mb-0" style="display: inline;">Police Station Report</h2>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-3 mb-3">
							<label class="form-label">Division</label>
							<select class="form-control border-0 border-bottom form-select"
								:class="{ 'is-invalid': errors?.division_id }" v-model="division_id"
								@change="getSubDivisions()">
								<option value="">Select Division</option>
								<option v-for="division, key in divisions" :value="division.division_id">{{
									division.division }}</option>
							</select>
							<span v-if="errors?.division_id" class="invalid-feedback">{{
								errors?.division_id[0] }}</span>
						</div>
						<div class="col-3 mb-3">
							<label class="form-label">Sub Division</label>
							<select class="form-control border-0 border-bottom form-select"
								@change="getPoliceStationByDivisions()"
								:class="{ 'is-invalid': errors?.sub_division_id }" v-model="sub_division_id"
								:disabled="!division_id">
								<option value="">Select Sub Division</option>
								<option v-for="sub_division, key in sub_divisions"
									:value="sub_division.sub_division_id">{{ sub_division.sub_division }}</option>
							</select>
							<span v-if="errors?.sub_division_id" class="invalid-feedback">{{
								errors?.sub_division_id[0] }}</span>
						</div>
						<div class="col-3 mb-3">
							<label class="form-label">Police Station</label>
							<search :class="{ 'is-invalid': errors?.police_station_id }"
								:customClass="{ 'is-invalid': errors?.police_station_id }"
								:initialize="police_station_id" id="police_station_id" label="police_station"
								placeholder="Select police station" :data="police_stations"
								@selected="onPoliceStationSelected">
							</search>
							<span v-if="errors.police_station_id" class="invalid-feedback">
								{{ errors.police_station_id[0] }}</span>
						</div>
						<div class="col-3 mb-3">
							<label class="form-label">&nbsp;</label>
							<div class="d-flex align-items-between">
								<button class="btn btn-outline-primary form-control mx-2"
									@click="clearSearch()">Clear</button>
								<button v-can="'download.reports'" class="btn btn-outline-success form-control mx-2"
									@click="downloadExcel()">Download</button>
							</div>
						</div>
					</div>
				</div>
				<div class="table-responsive card-body small" style="overflow-y:scroll; height: 75vh;">
					<table class="table table-hover table-sm table-striped table-bordered">
						<thead class="freeze-head">
							<tr>
								<th>S.L. No</th>
								<th v-show="col.division">Division</th>
								<th v-show="col.sub_division">Sub Division</th>
								<th v-show="col.police_station">Police Station</th>
								<!-- <th class="text-center">Rowdy Sheeters</th> -->
								<th class="text-center">Rowdy Sheeter<br> by PS</th>
								<th class="text-center">Category<br>A</th>
								<th class="text-center">Category<br>B</th>
								<th class="text-center">Category<br>C</th>
								<th class="text-center">Present</th>
								<th class="text-center">&nbsp;&nbsp;&nbsp;&nbsp;OV&nbsp;&nbsp;&nbsp;&nbsp;</th>
								<th class="text-center">&nbsp;&nbsp;&nbsp;&nbsp;JC&nbsp;&nbsp;&nbsp;&nbsp;</th>
								<th class="text-center">Active</th>
								<th class="text-center">InActive</th>
								<th class="text-center">Closed</th>
							</tr>
						</thead>
						<tbody>
							<tr class="" v-for="ele, key in statistics" :key="key">
								<td class="text-center">{{ key + 1 }}</td>
								<td class="text-nowrap" v-show="col.division">{{ ele.division.division }}</td>
								<td v-show="col.sub_division">{{ ele.sub_division.sub_division }}</td>
								<td v-show="col.police_station">{{ ele.police_station }}</td>
								<!-- <td class="text-center">{{ ele.rowdy_counts }}</td> -->
								<td class="text-center">{{ ele.total_counts }}</td>
								<td class="text-center">{{ ele.a_counts }}</td>
								<td class="text-center">{{ ele.b_counts }}</td>
								<td class="text-center">{{ ele.c_counts }}</td>
								<td class="text-center">{{ ele.present_counts }}</td>
								<td class="text-center">{{ ele.ov_counts }}</td>
								<td class="text-center">{{ ele.jc_counts }}</td>
								<td class="text-center">{{ ele.active_counts }}</td>
								<td class="text-center">{{ ele.inactive_counts }}</td>
								<td class="text-center">{{ ele.closed_counts }}</td>
							</tr>
							<tr class="text-center fw-bold">
								<td :colspan="colspan">TOTAL</td>
								<!-- <td>{{ totals.rowdy_counts }}</td> -->
								<td>{{ totals.total_counts }}</td>
								<td>{{ totals.a_counts }}</td>
								<td>{{ totals.b_counts }}</td>
								<td>{{ totals.c_counts }}</td>
								<td>{{ totals.present_counts }}</td>
								<td>{{ totals.ov_counts }}</td>
								<td>{{ totals.jc_counts }}</td>
								<td>{{ totals.active_counts }}</td>
								<td>{{ totals.inactive_counts }}</td>
								<td>{{ totals.closed_counts }}</td>
							</tr>
							<!-- <tr class="small" v-if="rowdies_with_incidents > 0">
                                <td colspan="14"><span class="text-primary"><i>Note: - Total rowdy sheeters with category: {{ rowdies_with_incidents }}</i></span></td>
                            </tr> -->
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
let Search = require("@/components/Search.vue").default;
export default {
	components: {
		Search
	},

	data() {
		return {
			division_id: '',
			sub_division_id: '',
			police_station_id: '',
			divisions: [],
			sub_divisions: [],
			police_stations: [],
			statistics: [],
			errors: [],
			col: {
				division: true,
				sub_division: true,
				police_station: true,
			},
			totals: {
				a_counts: 0,
				b_counts: 0,
				c_counts: 0,
				rowdy_counts: 0,
				total_counts: 0,
				present_counts: 0,
				ov_counts: 0,
				jc_counts: 0,
				active_counts: 0,
				inactive_counts: 0,
				closed_counts: 0,
			},
			colspan: 4,
			rowdies_with_incidents: 0,
		}
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.getDivisions();
			vm.getPoliceStations();
		})
	},
	watch: {
		division_id(val) {
			// if (val != '') {
			//     this.col.division = false;
			// } else {
			//     this.col.division = true;
			// }            
		},

		sub_division_id(val) {
			if (val != '') {
				this.col.division = false;
				this.colspan = 3;
			} else {
				this.colspan = 4;
				this.col.sub_division = true;
				this.col.division = true;
			}
		},

		police_station_id(val) {
			if (val != '') {
				this.col.police_station = true;
				this.col.sub_division = false;
				this.col.division = false;
				this.colspan = 2;
			} else {
				this.colspan = 3;
				this.col.police_station = true;
				this.col.sub_division = true;
			}
		},

	},
	methods: {
		getDivisions() {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getDivisions' })
				.then(response => {
					loader.hide();
					this.divisions = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getSubDivisions() {
			this.sub_division_id = '';
			this.police_station_id = '';
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getSubDivisions', data: { division_id: this.division_id } })
				.then(response => {
					loader.hide();
					this.sub_divisions = response.data.data;
					this.getRowdyCounts();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getPoliceStationByDivisions() {
			this.police_station_id = '';
			if (this.sub_division_id == '') {
				this.getRowdyCounts();
				return;
			}
			let vm = this;
			let data = {
				division_id: this.division_id,
				sub_division_id: this.sub_division_id,
				police_station_id: this.police_station_id
			}
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getPoliceStationByDivisions', data: data })
				.then(response => {
					loader.hide();
					this.police_stations = response.data.data;
					this.getRowdyCounts();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getPoliceStations() {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getPoliceStationByDivisions' })
				.then(response => {
					loader.hide();
					this.police_stations = response.data.data;
					this.getRowdyCounts();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getRowdyCounts() {
			this.errors = [];
			let vm = this;
			let loader = this.$loading.show();
			let data = {
				division_id: this.division_id,
				sub_division_id: this.sub_division_id,
				police_station_id: this.police_station_id
			};
			this.$store.dispatch('post', { uri: 'getRowdyCounts', data: data })
				.then(response => {
					loader.hide();
					this.statistics = response.data.data;
					this.rowdies_with_incidents = response.data.rowdies_with_incidents;
					this.calculateTotal();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},
		onPoliceStationSelected(police_station_id) {
			this.police_station_id = police_station_id;
			this.getRowdyCounts();
		},

		clearSearch() {
			this.division_id = '',
				this.sub_division_id = '',
				this.police_station_id = '',
				this.getRowdyCounts();
		},

		downloadExcel() {
			window.open(this.$store.getters.downloadUrl + "rowdyStatistics?division_id=" + this.division_id + "&sub_division_id=" + this.sub_division_id + "&police_station_id=" + this.police_station_id);
		},

		calculateTotal() {
			this.totals.a_counts = 0;
			this.totals.b_counts = 0;
			this.totals.c_counts = 0;
			this.totals.rowdy_counts = 0;
			this.totals.total_counts = 0;
			this.totals.present_counts = 0;
			this.totals.ov_counts = 0;
			this.totals.jc_counts = 0;
			this.totals.active_counts = 0;
			this.totals.inactive_counts = 0;
			this.totals.closed_counts = 0;
			this.statistics.forEach(ele => {
				this.totals.a_counts += ele.a_counts;
				this.totals.b_counts += ele.b_counts;
				this.totals.c_counts += ele.c_counts;
				this.totals.rowdy_counts += ele.rowdy_counts;
				this.totals.total_counts += ele.total_counts;
				this.totals.present_counts += ele.present_counts;
				this.totals.ov_counts += ele.ov_counts;
				this.totals.jc_counts += ele.jc_counts;
				this.totals.active_counts += ele.active_counts;
				this.totals.inactive_counts += ele.inactive_counts;
				this.totals.closed_counts += ele.closed_counts;
			})
		}
	}
}
</script>

<style scoped>
thead {
	top: -17px !important;
	z-index: 5 !important;
	background-color: #f3f5f9
}
</style>
