<template>
	<div class="d-md-flex align-items-center justify-content-between gap-4 mt-2 mb-4">
		<div>
			<h2 class="main-title fs-28 mb-0" style="display: inline;">Rowdy Sheeters Statistics</h2>
		</div>
		<div>
			<label class="form-label">&nbsp;</label>
			<div class="d-flex align-items-between">
				<button class="btn btn-outline-primary form-control mx-2" @click="clearSearch()">Clear</button>
				<button v-can="'download.reports'" class="btn btn-outline-success form-control mx-2"
					@click="downloadExcel()">Download</button>
			</div>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-3 mb-3">
							<label class="form-label">Division</label>
							<select class="form-control border-0 border-bottom form-select"
								:class="{ 'is-invalid': errors?.division_id }" v-model="division_id"
								@change="getSubDivisions()">
								<option value="">Select Division</option>
								<option v-for="division, key in divisions" :value="division.division_id">{{
									division.division }}</option>
							</select>
							<span v-if="errors?.division_id" class="invalid-feedback">{{
								errors?.division_id[0] }}</span>
						</div>
						<div class="col-3 mb-3">
							<label class="form-label">Sub Division</label>
							<select class="form-control border-0 border-bottom form-select"
								@change="getGroupByPoliceStations()" :class="{ 'is-invalid': errors?.sub_division_id }"
								v-model="sub_division_id" :disabled="!division_id">
								<option value="">Select Sub Division</option>
								<option v-for="sub_division, key in sub_divisions"
									:value="sub_division.sub_division_id">{{ sub_division.sub_division }}</option>
							</select>
							<span v-if="errors?.sub_division_id" class="invalid-feedback">{{
								errors?.sub_division_id[0] }}</span>
						</div>
						<div class="col-3 mb-3">
							<label class="form-label">&nbsp;</label>
							<div class="form-check mt-2">
								<input class="form-check-input" type="radio" value="all_sub_division" v-model="all_data"
									@change="getAllInformation()" />
								<label class="form-check-label" for=""> All Sub Divisions </label>
							</div>
						</div>
						<div class="col-3 mb-3">
							<label class="form-label">&nbsp;</label>
							<div class="form-check mt-2">
								<input class="form-check-input" type="radio" value="all_police_station"
									v-model="all_data" @change="getAllInformation()" />
								<label class="form-check-label" for=""> All Police Stations </label>
							</div>
						</div>
					</div>
					<div class="table-responsive small" style="overflow-y:scroll; height: 70vh;">
						<table class="table table-hover table-sm table-striped table-bordered" v-if="tab.division">
							<thead class="freeze-head">
								<tr>
									<th>S.L. No</th>
									<th>Division</th>
									<!-- <th class="text-center">Rowdy Sheeter</th> -->
									<th class="text-center">Rowdy Sheeter<br>By PS</th>
									<th class="text-center">Category<br>A</th>
									<th class="text-center">Category<br>B</th>
									<th class="text-center">Category<br>C</th>
									<th class="text-center">Present</th>
									<th class="text-center">&nbsp;&nbsp;OV&nbsp;&nbsp;</th>
									<th class="text-center">&nbsp;&nbsp;JC&nbsp;&nbsp;</th>
									<th class="text-center">Active</th>
									<th class="text-center">InActive</th>
									<th class="text-center">Closed</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="val, key in group_by_division_data" :key="key">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ val.division.division }}</td>
									<!-- <td class="text-center">{{ val.rowdy_counts }}</td> -->
									<td class="text-center">{{ val.total_counts }}</td>
									<td class="text-center">{{ val.a_counts }}</td>
									<td class="text-center">{{ val.b_counts }}</td>
									<td class="text-center">{{ val.c_counts }}</td>
									<td class="text-center">{{ val.present_counts }}</td>
									<td class="text-center">{{ val.ov_counts }}</td>
									<td class="text-center">{{ val.jc_counts }}</td>
									<td class="text-center">{{ val.active_counts }}</td>
									<td class="text-center">{{ val.inactive_counts }}</td>
									<td class="text-center">{{ val.closed_counts }}</td>
								</tr>
								<tr class="text-center fw-bold">
									<td colspan="2">TOTAL</td>
									<!-- <td>{{ totals.rowdy_counts }}</td> -->
									<td>{{ totals.total_counts }}</td>
									<td>{{ totals.a_counts }}</td>
									<td>{{ totals.b_counts }}</td>
									<td>{{ totals.c_counts }}</td>
									<td>{{ totals.present_counts }}</td>
									<td>{{ totals.ov_counts }}</td>
									<td>{{ totals.jc_counts }}</td>
									<td>{{ totals.active_counts }}</td>
									<td>{{ totals.inactive_counts }}</td>
									<td>{{ totals.closed_counts }}</td>
								</tr>
								<!-- <tr class="small" v-if="rowdies_with_incidents > 0">
									<td colspan="11"><span class="text-primary"><i>Note: - Total rowdy sheeters with
												category: {{ rowdies_with_incidents }}</i></span></td>
								</tr> -->
							</tbody>
						</table>
						<table class="table table-hover table-sm table-striped table-bordered" v-if="tab.sub_division">
							<thead class="freeze-head">
								<tr>
									<th>S.L. No</th>
									<th>Sub Division</th>
									<!-- <th class="text-center">Rowdy Sheeter</th> -->
									<th class="text-center">Rowdy Sheeter<br>By PS</th>
									<th class="text-center">Category<br>A</th>
									<th class="text-center">Category<br>B</th>
									<th class="text-center">Category<br>C</th>
									<th class="text-center">Present</th>
									<th class="text-center">&nbsp;&nbsp;OV&nbsp;&nbsp;</th>
									<th class="text-center">&nbsp;&nbsp;JC&nbsp;&nbsp;</th>
									<th class="text-center">Active</th>
									<th class="text-center">InActive</th>
									<th class="text-center">Closed</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="val, key in group_by_sub_division_data" :key="key">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ val.sub_division.sub_division }}</td>
									<!-- <td class="text-center">{{ val.rowdy_counts }}</td> -->
									<td class="text-center">{{ val.total_counts }}</td>
									<td class="text-center">{{ val.a_counts }}</td>
									<td class="text-center">{{ val.b_counts }}</td>
									<td class="text-center">{{ val.c_counts }}</td>
									<td class="text-center">{{ val.present_counts }}</td>
									<td class="text-center">{{ val.ov_counts }}</td>
									<td class="text-center">{{ val.jc_counts }}</td>
									<td class="text-center">{{ val.active_counts }}</td>
									<td class="text-center">{{ val.inactive_counts }}</td>
									<td class="text-center">{{ val.closed_counts }}</td>
								</tr>
								<tr class="text-center fw-bold">
									<td colspan="2">TOTAL</td>
									<!-- <td>{{ totals.rowdy_counts }}</td> -->
									<td>{{ totals.total_counts }}</td>
									<td>{{ totals.a_counts }}</td>
									<td>{{ totals.b_counts }}</td>
									<td>{{ totals.c_counts }}</td>
									<td>{{ totals.present_counts }}</td>
									<td>{{ totals.ov_counts }}</td>
									<td>{{ totals.jc_counts }}</td>
									<td>{{ totals.active_counts }}</td>
									<td>{{ totals.inactive_counts }}</td>
									<td>{{ totals.closed_counts }}</td>
								</tr>
								<!-- <tr class="small" v-if="rowdies_with_incidents > 0">
									<td colspan="11"><span class="text-primary"><i>Note: - Total rowdy sheeters with
												category: {{ rowdies_with_incidents }}</i></span></td>
								</tr> -->
							</tbody>
						</table>
						<table class="table table-hover table-sm table-striped table-bordered"
							v-if="tab.police_station">
							<thead class="freeze-head">
								<tr>
									<th>S.L. No</th>
									<th>Police Station</th>
									<!-- <th class="text-center">Rowdy Sheeter</th> -->
									<th class="text-center">Rowdy Sheeter<br>By PS</th>
									<th class="text-center">Category<br>A</th>
									<th class="text-center">Category<br>B</th>
									<th class="text-center">Category<br>C</th>
									<th class="text-center">Present</th>
									<th class="text-center">&nbsp;&nbsp;OV&nbsp;&nbsp;</th>
									<th class="text-center">&nbsp;&nbsp;JC&nbsp;&nbsp;</th>
									<th class="text-center">Active</th>
									<th class="text-center">InActive</th>
									<th class="text-center">Closed</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="val, key in group_by_police_station_data" :key="key">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ val.police_station }}</td>
									<!-- <td class="text-center">{{ val.rowdy_counts }}</td> -->
									<td class="text-center">{{ val.total_counts }}</td>
									<td class="text-center">{{ val.a_counts }}</td>
									<td class="text-center">{{ val.b_counts }}</td>
									<td class="text-center">{{ val.c_counts }}</td>
									<td class="text-center">{{ val.present_counts }}</td>
									<td class="text-center">{{ val.ov_counts }}</td>
									<td class="text-center">{{ val.jc_counts }}</td>
									<td class="text-center">{{ val.active_counts }}</td>
									<td class="text-center">{{ val.inactive_counts }}</td>
									<td class="text-center">{{ val.closed_counts }}</td>
								</tr>
								<tr class="text-center fw-bold">
									<td colspan="2">TOTAL</td>
									<!-- <td>{{ totals.total_counts }}</td> -->
									<td>{{ totals.total_counts }}</td>
									<td>{{ totals.a_counts }}</td>
									<td>{{ totals.b_counts }}</td>
									<td>{{ totals.c_counts }}</td>
									<td>{{ totals.present_counts }}</td>
									<td>{{ totals.ov_counts }}</td>
									<td>{{ totals.jc_counts }}</td>
									<td>{{ totals.active_counts }}</td>
									<td>{{ totals.inactive_counts }}</td>
									<td>{{ totals.closed_counts }}</td>
								</tr>
								<!-- <tr class="small" v-if="rowdies_with_incidents > 0">
									<td colspan="11"><span class="text-primary"><i>Note: - Total rowdy sheeters with
												category: {{ rowdies_with_incidents }}</i></span></td>
								</tr> -->
							</tbody>
						</table>
						<table class="table table-hover table-sm table-striped table-bordered"
							v-if="tab.all_sub_divisions">
							<thead class="freeze-head">
								<tr>
									<th colspan="12" class="text-center text-primary">ALL SUB DIVISIONS</th>
								</tr>
								<tr>
									<th>S.L. No</th>
									<th>Sub Division</th>
									<!-- <th class="text-center">Rowdy Sheeter</th> -->
									<th class="text-center">Rowdy Sheeter<br>By PS</th>
									<th class="text-center">Category<br>A</th>
									<th class="text-center">Category<br>B</th>
									<th class="text-center">Category<br>C</th>
									<th class="text-center">Present</th>
									<th class="text-center">&nbsp;&nbsp;OV&nbsp;&nbsp;</th>
									<th class="text-center">&nbsp;&nbsp;JC&nbsp;&nbsp;</th>
									<th class="text-center">Active</th>
									<th class="text-center">InActive</th>
									<th class="text-center">Closed</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="val, key in all_sub_division_wise" :key="key">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ val.sub_division.sub_division }}</td>
									<!-- <td class="text-center">{{ val.rowdy_counts }}</td> -->
									<td class="text-center">{{ val.total_counts }}</td>
									<td class="text-center">{{ val.a_counts }}</td>
									<td class="text-center">{{ val.b_counts }}</td>
									<td class="text-center">{{ val.c_counts }}</td>
									<td class="text-center">{{ val.present_counts }}</td>
									<td class="text-center">{{ val.ov_counts }}</td>
									<td class="text-center">{{ val.jc_counts }}</td>
									<td class="text-center">{{ val.active_counts }}</td>
									<td class="text-center">{{ val.inactive_counts }}</td>
									<td class="text-center">{{ val.closed_counts }}</td>
								</tr>
								<tr class="text-center fw-bold">
									<td colspan="2">TOTAL</td>
									<!-- <td>{{ totals.rowdy_counts }}</td> -->
									<td>{{ totals.total_counts }}</td>
									<td>{{ totals.a_counts }}</td>
									<td>{{ totals.b_counts }}</td>
									<td>{{ totals.c_counts }}</td>
									<td>{{ totals.present_counts }}</td>
									<td>{{ totals.ov_counts }}</td>
									<td>{{ totals.jc_counts }}</td>
									<td>{{ totals.active_counts }}</td>
									<td>{{ totals.inactive_counts }}</td>
									<td>{{ totals.closed_counts }}</td>
								</tr>
								<!-- <tr class="small" v-if="rowdies_with_incidents > 0">
									<td colspan="11"><span class="text-primary"><i>Note: - Total rowdy sheeters with
												category: {{ rowdies_with_incidents }}</i></span></td>
								</tr> -->
							</tbody>
						</table>
						<table class="table table-hover table-sm table-striped table-bordered"
							v-if="tab.all_police_stations">
							<thead class="freeze-head">
								<tr>
									<th colspan="12" class="text-center text-primary">ALL POLICE STATIONS</th>
								</tr>
								<tr>
									<th>S.L. No</th>
									<th>Police Station</th>
									<!-- <th class="text-center">Rowdy Sheeter</th> -->
									<th class="text-center">Rowdy Sheeter<br>By PS</th>
									<th class="text-center">Category<br>A</th>
									<th class="text-center">Category<br>B</th>
									<th class="text-center">Category<br>C</th>
									<th class="text-center">Present</th>
									<th class="text-center">&nbsp;&nbsp;OV&nbsp;&nbsp;</th>
									<th class="text-center">&nbsp;&nbsp;JC&nbsp;&nbsp;</th>
									<th class="text-center">Active</th>
									<th class="text-center">InActive</th>
									<th class="text-center">Closed</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="val, key in all_police_station_wise" :key="key">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ val.police_station }}</td>
									<!-- <td class="text-center">{{ val.rowdy_counts }}</td> -->
									<td class="text-center">{{ val.total_counts }}</td>
									<td class="text-center">{{ val.a_counts }}</td>
									<td class="text-center">{{ val.b_counts }}</td>
									<td class="text-center">{{ val.c_counts }}</td>
									<td class="text-center">{{ val.present_counts }}</td>
									<td class="text-center">{{ val.ov_counts }}</td>
									<td class="text-center">{{ val.jc_counts }}</td>
									<td class="text-center">{{ val.active_counts }}</td>
									<td class="text-center">{{ val.inactive_counts }}</td>
									<td class="text-center">{{ val.closed_counts }}</td>
								</tr>
								<tr class="text-center fw-bold">
									<td colspan="2">TOTAL</td>
									<!-- <td>{{ totals.rowdy_counts }}</td> -->
									<td>{{ totals.total_counts }}</td>
									<td>{{ totals.a_counts }}</td>
									<td>{{ totals.b_counts }}</td>
									<td>{{ totals.c_counts }}</td>
									<td>{{ totals.present_counts }}</td>
									<td>{{ totals.ov_counts }}</td>
									<td>{{ totals.jc_counts }}</td>
									<td>{{ totals.active_counts }}</td>
									<td>{{ totals.inactive_counts }}</td>
									<td>{{ totals.closed_counts }}</td>
								</tr>
								<!-- <tr class="small" v-if="rowdies_with_incidents > 0">
									<td colspan="11"><span class="text-primary"><i>Note: - Total rowdy sheeters with
												category: {{ rowdies_with_incidents }}</i></span></td>
								</tr> -->
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			division_id: '',
			sub_division_id: '',
			police_station_id: '',
			divisions: [],
			sub_divisions: [],
			police_stations: [],
			group_by_division_data: [],
			group_by_sub_division_data: [],
			group_by_police_station_data: [],
			all_sub_division_wise: [],
			all_police_station_wise: [],
			errors: [],
			totals: {
				a_counts: 0,
				b_counts: 0,
				c_counts: 0,
				rowdy_counts: 0,
				total_counts: 0,
				present_counts: 0,
				ov_counts: 0,
				jc_counts: 0,
				active_counts: 0,
				inactive_counts: 0,
				closed_counts: 0,
			},
			tab: {
				division: true,
				sub_division: false,
				police_station: false,
				all_sub_divisions: false,
				all_police_stations: false
			},
			all_data: '',
			rowdies_with_incidents: 0,
		}
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.getGroupByDivisions();
			vm.getDivisions();
		})
	},

	watch: {
		division_id(val) {
			if (val != '') {
				this.setTab('sub_division');
			}
		},

		sub_division_id(val) {
			if (val != '') {
				this.setTab('police_station');
			}
		},

		all_data(val) {
			if (val == 'all_sub_division') {
				this.tab.division = false;
				this.tab.sub_division = false;
				this.tab.police_station = false;
				this.tab.all_sub_divisions = true;
				this.tab.all_police_stations = false;
			}
		}
	},
	methods: {
		getDivisions() {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getDivisions' })
				.then(response => {
					loader.hide();
					this.divisions = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getSubDivisions() {
			if (this.division_id == '') {
				this.sub_division_id = '';
				this.police_station_id = '';
				this.setTab('division');
				this.getGroupByDivisions();
				return;
			}
			this.sub_division_id = '';
			this.police_station_id = '';
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getSubDivisions', data: { division_id: this.division_id } })
				.then(response => {
					loader.hide();
					this.sub_divisions = response.data.data;
					this.getGroupBySubDivisions();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getGroupByDivisions() {
			this.errors = [];
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getGroupByDivisions' })
				.then(response => {
					loader.hide();
					this.group_by_division_data = response.data.data;
					this.rowdies_with_incidents = response.data.rowdies_with_incidents;
					this.calculateTotal(this.group_by_division_data);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getGroupBySubDivisions() {
			if (this.division_id == '') {
				this.sub_division_id = '';
				this.getGroupByDivisions();
				return;
			};
			this.sub_division_id = '';
			this.errors = [];
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getGroupBySubDivisions', data: { division_id: this.division_id } })
				.then(response => {
					loader.hide();
					this.group_by_sub_division_data = response.data.data;
					this.rowdies_with_incidents = response.data.rowdies_with_incidents;
					this.calculateTotal(this.group_by_sub_division_data);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getGroupByPoliceStations() {
			if (this.sub_division_id == '') {
				this.getGroupBySubDivisions();
				this.setTab('sub_division');
				return;
			}
			this.errors = [];
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getGroupByPoliceStations', data: { sub_division_id: this.sub_division_id } })
				.then(response => {
					loader.hide();
					this.group_by_police_station_data = response.data.data;
					this.rowdies_with_incidents = response.data.rowdies_with_incidents;
					this.calculateTotal(this.group_by_police_station_data);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},
		clearSearch() {
			this.division_id = '';
			this.sub_division_id = '';
			this.police_station_id = '';
			this.all_data = '';
			this.getGroupByDivisions();
			this.setTab('division');
		},

		downloadExcel() {
			if (this.tab.division) {
				window.open(this.$store.getters.downloadUrl + "groupByDivision");
			} else if (this.tab.sub_division) {
				window.open(this.$store.getters.downloadUrl + "groupBySubDivision?division_id=" + this.division_id);
			} else if (this.tab.police_station) {
				window.open(this.$store.getters.downloadUrl + "groupByPoliceStation?sub_division_id=" + this.sub_division_id);
			} else if (this.tab.all_sub_divisions) {
				window.open(this.$store.getters.downloadUrl + "groupByAllSubDivision");
			} else if (this.tab.all_police_stations) {
				window.open(this.$store.getters.downloadUrl + "groupByAllPoliceStation");
			}
		},

		calculateTotal(data) {
			this.totals.a_counts = 0;
			this.totals.b_counts = 0;
			this.totals.c_counts = 0;
			this.totals.total_counts = 0;
			this.totals.rowdy_counts = 0;
			this.totals.present_counts = 0;
			this.totals.ov_counts = 0;
			this.totals.jc_counts = 0;
			this.totals.active_counts = 0;
			this.totals.inactive_counts = 0;
			this.totals.closed_counts = 0;
			data.forEach(ele => {
				this.totals.a_counts += ele.a_counts;
				this.totals.b_counts += ele.b_counts;
				this.totals.c_counts += ele.c_counts;
				this.totals.rowdy_counts += ele.rowdy_counts;
				this.totals.total_counts += ele.total_counts;
				this.totals.present_counts += ele.present_counts;
				this.totals.ov_counts += ele.ov_counts;
				this.totals.jc_counts += ele.jc_counts;
				this.totals.active_counts += ele.active_counts;
				this.totals.inactive_counts += ele.inactive_counts;
				this.totals.closed_counts += ele.closed_counts;
			})
		},

		getAllInformation() {
			if (this.all_data == 'all_sub_division') {
				this.getAllSubDivisionWise();
			} else {
				this.getAllPoliceStationWise();
			}
		},
		getAllSubDivisionWise() {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getAllSubDivisionWise' })
				.then(response => {
					loader.hide();
					this.all_sub_division_wise = response.data.data;
					this.rowdies_with_incidents = response.data.rowdies_with_incidents;
					this.calculateTotal(this.all_sub_division_wise);
					this.setTab('all_sub_divisions');
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},
		getAllPoliceStationWise() {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getAllPoliceStationWise' })
				.then(response => {
					loader.hide();
					this.all_police_station_wise = response.data.data;
					this.rowdies_with_incidents = response.data.rowdies_with_incidents;
					this.calculateTotal(this.all_police_station_wise);
					this.setTab('all_police_stations');
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},
		setTab(tab) {
			this.tab.division = false;
			this.tab.sub_division = false;
			this.tab.police_station = false;
			this.tab.all_sub_divisions = false;
			this.tab.all_police_stations = false;

			switch (tab) {
				case 'division':
					this.tab.division = true;
					break;
				case 'sub_division':
					this.tab.sub_division = true;
					break;
				case 'police_station':
					this.tab.police_station = true;
					break;
				case 'all_sub_divisions':
					this.tab.all_sub_divisions = true;
					break;
				case 'all_police_stations':
					this.tab.all_police_stations = true;
					break;
				default:
					break;
			}
		}
	}
}
</script>

<style scoped>
thead {
	top: -17px !important;
	z-index: 5 !important;
	background-color: #f3f5f9
}
</style>
