<template>
	<div class="row g-3">
		<div class="col-xl-12">
			<div class="card card-one py-0">
				<div class="card-body">
					<div class="d-md-flex align-items-center justify-content-between">
						<div>
							<div class="d-flex align-items-center gap-2 mb-2">
								<div class="d-sm-flex p-2 p-sm-4 p-md-2 p-xl-2">
									<router-link :to="path" class="text-dark m-auto"><i
											class="ri-arrow-left-line fs-24"></i></router-link>
									<div id="cAvatar" class="me-2 mb-3 mb-sm-0">
										<a data-bs-toggle="modal" data-bs-target="#exampleModal">
											<div class="avatar avatar-xl"><img class="leader-img1"
													:src="rowdy.rowdy_sheeter_photo" alt="" /></div>
										</a>
									</div>
									<div class="flex-fill">
										<span v-if="rowdy.follower_type"
											class="badge bg-light-secondary rounded-pill">Gang {{ rowdy?.follower_type
											}}</span>
										<h5 id="cName" class="mb-0 fs-20 text-dark fw-semibold">{{
											rowdy.rowdy_sheeter_name }}</h5>
										<small>
											<!-- LEFT THE GANG 23 MARCH 2034 . -->
											<span v-if="rowdy.gang_name" class="fw-semibold"> -> Present Gang<span
													class="text-primary"> :
													{{ rowdy.gang_name }}</span></span>
										</small>
									</div>
								</div>
							</div>
						</div>
						<div class="d-flex gap-3 mt-3 mt-md-0">
							<div class="row row-cols-auto g-3 g-xl-4 pt-2">
								<div class="col border-right">
									<h6 class="card-value fs-18 mb-1 justify-content-center">{{ rowdy.no_of_cases }}
									</h6>
									<span class="fs-sm fw-medium d-block mb-1 font-gray">Cases</span>
								</div>
								<div class="col border-right">
									<h6 class="card-value fs-18 mb-1 justify-content-center">{{ rowdy.process_count }}
									</h6>
									<span class="fs-sm fw-medium font-gray d-block mb-1">Process</span>
								</div>
								<div class="col">
									<h6 class="card-value fs-18 mb-1 justify-content-center">{{ rowdy.goonda_count }}
									</h6>
									<span class="fs-sm fw-medium font-gray d-block mb-1"
										title="Goonda Act / Externment">G Act/Ext</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-xl-12">
			<div class="card1 card-one1 py-4">
				<div class="card-body1">
					<ul class="nav nav-tabs card-header-tabs mb-4" data-bs-tabs="tabs">
						<li class="nav-item">
							<a class="nav-link text-dark fw-semibold fs-16 active" aria-current="true"
								data-bs-toggle="tab" href="#details">Personal Details</a>
						</li>
						<li class="nav-item" @click="getCases(rowdy.rowdy_id)">
							<a class="nav-link text-dark fw-semibold fs-16" data-bs-toggle="tab" href="#cases">Cases</a>
						</li>
						<li class="nav-item" @click="getProcesses(rowdy.rowdy_id)">
							<a class="nav-link text-dark fw-semibold fs-16" data-bs-toggle="tab"
								href="#process">Process</a>
						</li>
						<li class="nav-item" @click="getGoondas(rowdy.rowdy_id)">
							<a class="nav-link text-dark fw-semibold fs-16" data-bs-toggle="tab" href="#goonda">Goonda
								Act / Externment / KCOCA Act</a>
						</li>
					</ul>
					<div class="card-body tab-content">
						<div class="tab-pane active" id="details">
							<div class="row">
								<div class="col-sm-3">
									<div class="d-block align-items-start align-items-sm-center gap-4 card mt-3">
										<img :src="rowdy.rowdy_sheeter_photo ? rowdy.rowdy_sheeter_photo : '/assets/img/avatar.jpg'"
											alt="user-avatar" width="100%" id="uploadedAvatar" />
										<div class="button-wrapper text-center">
											<label for="upload" class="btn btn-primary me-2 mt-2" tabindex="0">
												<span class="d-none d-sm-block">Upload Primary
													Photo</span>
												<i class="bx bx-upload d-block d-sm-none"></i>
												<input type="file" id="upload" class="account-file-input" hidden
													@change="onImageChange($event)" accept="image/png, image/jpeg" />
											</label>
										</div>
										<span class="invalid-feedback">{{ }}</span>
										<div class="button-wrapper text-center mb-3">
											<label class="btn btn-primary me-2 mt-2" tabindex="0">
												<span class="d-none d-sm-block">Upload Multiple
													Images</span>
												<input type="file" id="" @change="uploadMultipleImages()"
													class="account-file-input" hidden ref="file"
													accept="image/png, image/jpeg" multiple />
											</label>
										</div>
										<span class="invalid-feedback">{{ }}</span>
									</div>
								</div>
								<div class="col-sm-9">
									<div class="row">
										<div class="col-6 mb-3">
											<label class="form-label">FPB Coid Number</label>
											&nbsp;
											<a v-if="update_status" href="javascript::void(0)" data-bs-toggle="modal"
												data-bs-target="#infoModal"
												@click="getRowdyEditHistory('fpb_coid_number')"><i
													class="ri-information-2-line"></i></a>
											<input type="text" class="form-control border-0 border-bottom"
												v-model="rowdy.fpb_coid_number"
												:class="{ 'is-invalid': errors.fpb_coid_number }"
												placeholder="Enter FPB Coid number" />
											<span v-if="errors.fpb_coid_number">{{ errors.fpb_coid_number[0]
												}}</span>
										</div>
										<div class="col-6 mb-3">
											<label class="form-label">Rowdy Sheeter Name</label>
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('rowdy_sheeter_name')"><i
													class="ri-information-2-line"></i></a>
											<!-- <span class="text-danger">*</span> -->
											<input type="text" class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors.rowdy_sheeter_name }"
												placeholder="Enter rowdy sheeter name"
												v-model="rowdy.rowdy_sheeter_name" />
											<span v-if="errors.rowdy_sheeter_name" class="invalid-feedback">{{
												errors.rowdy_sheeter_name[0] }}</span>
										</div>
										<div class="col-6 mb-3">
											<label class="form-label">Alias</label>
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('alias')"><i
													class="ri-information-2-line"></i></a>
											<input type="text" class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors.alias }" placeholder="Enter alias"
												v-model="rowdy.alias" />
											<span v-if="errors.alias" class="invalid-feedback">{{
												errors.alias[0] }}</span>
										</div>
										<div class="col-6 mb-3">
											<label class="form-label">Father Name</label>
											&nbsp;<a v-if="update_status" href="javascript::void(0)"
												data-bs-toggle="modal" data-bs-target="#infoModal"
												@click="getRowdyEditHistory('father_name')"><i
													class="ri-information-2-line"></i></a>
											<input type="text" class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors.father_name }"
												placeholder="Enter father name" v-model="rowdy.father_name" />
											<span v-if="errors.father_name" class="invalid-feedback">{{
												errors.father_name[0] }}</span>
										</div>
										<div class="col-6 mb-3">
											<label class="form-label">Mobile Number</label>
											&nbsp;
											<a v-if="update_status" href="javascript::void(0)" data-bs-toggle="modal"
												data-bs-target="#infoModal"
												@click="getRowdyEditHistory('brother_in_law_details')"><i
													class="ri-information-2-line"></i></a>
											<input type="text" class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors.mobile_no }"
												placeholder="Enter mobile number" v-model="rowdy.mobile_no" />
											<span v-if="errors.mobile_no" class="invalid-feedback">{{
												errors.mobile_no[0] }}</span>
										</div>
										<div class="col-6 mb-3">
											<label class="form-label">Aadhaar</label>
											&nbsp;
											<a v-if="update_status" href="javascript::void(0)" data-bs-toggle="modal"
												data-bs-target="#infoModal"
												@click="getRowdyEditHistory('aadhaar_number')"><i
													class="ri-information-2-line"></i></a>
											<input type="text" class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors.aadhaar_number }"
												placeholder="Enter aadhaar number" v-model="rowdy.aadhaar_number" />
											<span v-if="errors.aadhaar_number" class="invalid-feedback">{{
												errors.aadhaar_number[0] }}</span>
										</div>
										<div class="col-12">
											<label class="form-label">Present Address</label>
											&nbsp;
											<a v-if="update_status" href="javascript::void(0)" data-bs-toggle="modal"
												data-bs-target="#infoModal"
												@click="getRowdyEditHistory('present_address')"><i
													class="ri-information-2-line"></i></a>
											<textarea class="form-control border-0 border-bottom"
												:class="{ 'is-invalid': errors.present_address }"
												placeholder="Enter present address..." cols="30"
												v-model="rowdy.present_address" rows="2"></textarea>
											<span v-if="errors.present_address" class="invalid-feedback">{{
												errors.present_address[0] }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="card my-2 small">
								<div class="card-header">
									<span class="h5">Rowdy Category</span>
								</div>
								<div class="row card-body">
									<div class="col-2">
										<label for="" class="form-label">Open Date</label>
										<input type="date" class="form-control border-0 border-bottom"
											placeholder="Rowdy Openned Date" v-model="rowdy_category.openned_date">
									</div>
									<div class="col-3">
										<label class="form-label">Police Station</label>
										<search :class="{ 'is-invalid': errors?.police_station_id }"
											class="bord er-0 border-bottom"
											:customClass="{ 'is-invalid': errors?.police_station_id }"
											:initialize="openned_police_station_id" id="police_station_id"
											label="police_station" placeholder="Police station" :data="police_stations"
											@selected="police_station => openned_police_station_id = police_station">
										</search>
										<span v-if="errors.police_station_id" class="invalid-feedback">{{
											errors.police_station_id[0] }}</span>
									</div>
									<div class="col-2">
										<label for="" class="form-label">Category</label>
										<select name="" id="" class="form-control border-0 border-bottom form-select"
											v-model="rowdy_category.category">
											<option value="">Select</option>
											<option value="A">A</option>
											<option value="B">B</option>
											<option value="C">C</option>
										</select>
									</div>
									<div class="col-2">
										<label for="" class="form-label">Closed Date</label>
										<input type="date" class="form-control border-0 border-bottom"
											v-model="rowdy_category.closed_date" placeholder="Rowdy Closed Date">
									</div>
									<div class="col-2">
										<label class="form-label">Where Abouts</label>
										<select name="" id="" class="form-control border-0 border-bottom form-select"
											v-model="rowdy_category.where_abouts">
											<option value="">Select</option>
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
											<option value="Closed">Closed</option>
											<option value="Transfer">Transfer</option>
											<option value="Murdered">Murdered</option>
											<option value="Died">Died</option>
											<option value="Suicide">Suicide</option>
										</select>
									</div>
									<div class="col-1">
										<label for="" class="form-label">&nbsp;</label>
										<button class="btn btn-sm btn-outline-primary form-control"
											@click="addRowdyCategory()">ADD</button>
									</div>

									<div class="col-12">
										<div class="table-responsive card mt-3">
											<table class="table table-sm table-bordered">
												<thead>
													<tr class="text-center">
														<th>SL NO</th>
														<th>Openned Date</th>
														<th>Police Station</th>
														<th>Category</th>
														<th>Closed Date</th>
														<th>Where abouts?</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="category, key in rowdy_categories" :key="key"
														class="text-center"
														:class="{ rowdy_categories: !category.rowdy_category_id }">
														<td>{{ key + 1 }}</td>
														<!-- <td>{{ category.openned_date }}</td> -->
														<td>
															<input type="date"
																class="form-control border-0 border-bottom"
																:class="{ rowdy_categories: !category.rowdy_category_id }"
																placeholder="Rowdy Openned Date"
																v-model="category.openned_date">
														</td>
														<!-- <td>{{ category.police_station }}</td> -->
														<td>
															<search :class="{ 'is-invalid': errors?.police_station_id }"
																class="bord er-0 border-bottom"
																:customClass="{ 'is-invalid': errors?.police_station_id }"
																:initialize="category.police_station_id"
																id="police_station_id" label="police_station"
																placeholder="Police station" :data="police_stations"
																@selected="police_station => category.police_station_id = police_station">
															</search>
														</td>
														<!-- <td>{{ category.category }}</td> -->
														<td>
															<select name="" id=""
																:class="{ rowdy_categories: !category.rowdy_category_id }"
																class="form-control border-0 border-bottom form-select"
																v-model="category.category">
																<option value="">Select</option>
																<option value="A">A</option>
																<option value="B">B</option>
																<option value="C">C</option>
															</select>
														</td>
														<!-- <td>{{ category.closed_date }}</td> -->
														<td>
															<input type="date"
																:class="{ rowdy_categories: !category.rowdy_category_id }"
																class="form-control border-0 border-bottom"
																v-model="category.closed_date"
																placeholder="Rowdy Closed Date">
														</td>
														<!-- <td>{{ category.where_abouts }}</td> -->
														<td>
															<select name="" id=""
																class="form-control border-0 border-bottom form-select"
																v-model="category.where_abouts"
																:class="{ rowdy_categories: !category.rowdy_category_id }">
																<option value=null>Select</option>
																<option value="">Select</option>
																<option value="Active">Active</option>
																<option value="Inactive">Inactive</option>
																<option value="Closed">Closed</option>
																<option value="Transfer">Transfer</option>
																<option value="Murdered">Murdered</option>
																<option value="Died">Died</option>
																<option value="Suicide">Suicide</option>
															</select>
														</td>
														<td>
															<button class="btn btn-sm btn-outline-danger"
																@click="removeRowdyCategory(category)">X</button>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<button class="btn btn-primary float-end" @click="updateRowdy()">Update</button>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="cases">
							<div class="row row-cols-auto g-xl-4 gap-1  pt-2">
								<div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#ipcModal"
									@click="getIpcCases(rowdy.rowdy_id, '302')">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.cases_302 }}</h6>
									<span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">302 IPC</span>
								</div>
								<div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#ipcModal"
									@click="getIpcCases(rowdy.rowdy_id, '307')">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.cases_307 }}</h6>
									<span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">307 IPC</span>
								</div>
								<div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#ipcModal"
									@click="getIpcCases(rowdy.rowdy_id, '149')">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.cases_149 }}</h6>
									<span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">149 IPC</span>
								</div>
								<div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#ipcModal"
									@click="getIpcCases(rowdy.rowdy_id, '420')">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.cases_420 }}</h6>
									<span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">420 IPC</span>
								</div>
								<div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#ipcModal"
									@click="getIpcCases(rowdy.rowdy_id, 'robbery')">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.cases_robbery }}
									</h6>
									<span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">Robbery</span>
								</div>
								<div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#ipcModal"
									@click="getIpcCases(rowdy.rowdy_id, 'others')">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.others }}
									</h6>
									<span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">Others</span>
								</div>
								<div class="col mt-2" data-bs-toggle="modal" data-bs-target="#casesModal"
									@click="getCases(rowdy.rowdy_id)">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.cases_type_total
										}}</h6>
									<span class="fs-sm fs-14 fw-medium text-dark d-block mb-1">Total</span>
								</div>
							</div>
							<div class="row">
								<div class="col-md">
									<router-link :to="'/rowdy_case_create/' + rowdy.rowdy_id"
										class="btn btn-sm btn-primary float-end">Add Case</router-link>
								</div>
							</div>
							<div class="card small mt-2">
								<div class="table-responsive" style="overflow-y:scroll; height: 45vh;">
									<table class="table table-hover table-sm table-bordered table-striped mb-0">
										<thead>
											<tr>
												<th>SL NO</th>
												<th>Police Station</th>
												<th>Crime Number</th>
												<th>Section of Law</th>
												<th>Fir Stage</th>
												<th>Warrant Issued</th>
												<th>Victim/Deceased</th>
												<th>Accomplices Names</th>
												<th>Case Status</th>
												<th>Court Name</th>
												<th>CC / SC Number</th>
												<th>Next Hearing Date</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="rowdy_case, key in cases">
												<td>{{ key + 1 }}</td>
												<td>{{ rowdy_case.police_station?.police_station }}</td>
												<td>{{ rowdy_case.crime_number }}</td>
												<td>{{ rowdy_case.section_of_law }}</td>
												<td>{{ rowdy_case.fir_stage }}</td>
												<td>{{ rowdy_case.warrant_issued ? 'Yes' : 'No' }}</td>
												<td>{{ rowdy_case.victim_deceased }}</td>
												<td>{{ rowdy_case.accomplices_names }}</td>
												<td>{{ rowdy_case.case_status }}</td>
												<td>{{ rowdy_case.court_name }}</td>
												<td>{{ rowdy_case.cc_sc_number }}</td>
												<td>{{ rowdy_case.next_hearing_date }}</td>
												<td><button class="btn btn-sm btn-primary"
														@click="editIpcCase(rowdy_case.rowdies_case_id)"><i
															class="ri-pencil-line"></i></button></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="process">
							<div class="row row-cols-auto g-xl-4 gap-1  pt-2">
								<div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#processModal"
									@click="getProcesses(rowdy.rowdy_id, 'notices')">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										rowdy?.process_notices_count }}</h6>
									<label class="card-title fw-semibold mb-2">Notices</label>
								</div>
								<div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#processModal"
									@click="getProcesses(rowdy.rowdy_id, 'warrant')">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										rowdy?.process_warrants_count }}</h6>
									<label class="card-title fw-semibold mb-2">Warrants</label>
								</div>
								<div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#processModal"
									@click="getProcesses(rowdy.rowdy_id, 'summons')">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										rowdy?.process_summons_count }}</h6>
									<label class="card-title fw-semibold mb-2">Summons</label>
								</div>
								<div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#processModal"
									@click="getProcesses(rowdy.rowdy_id, 'proclamation')">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										rowdy?.process_proclamation_count }}
									</h6>
									<label class="card-title fw-semibold mb-2">Proclamation</label>
								</div>
								<div class="col mt-2" data-bs-toggle="modal" data-bs-target="#processModal"
									@click="getProcesses(rowdy.rowdy_id)">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.process_count }}
									</h6>
									<label class="card-title fw-semibold mb-2">Total</label>
								</div>
							</div>
							<div class="row">
								<div class="col-md">
									<router-link :to="'/rowdy_process_create/' + rowdy.rowdy_id"
										class="btn btn-sm btn-primary float-end">Add Process</router-link>
								</div>
							</div>
							<div class="card small mt-2">
								<div class="table-responsive" style="overflow-y:scroll; height: 45vh;">
									<table class="table table-hover table-sm table-bordered table-striped mb-0">
										<thead>
											<tr>
												<th>SL NO</th>
												<th>Process Type</th>
												<th>Police Station</th>
												<th>Crime Number</th>
												<th>Section of Law</th>
												<th>Court Name</th>
												<th>CC / SC Number</th>
												<th>Issued Date</th>
												<th>Served/Unserved</th>
												<th>Next Hearing Date</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="process, key in processes">
												<td>{{ key + 1 }}</td>
												<td>{{ process.process_type }}</td>
												<td>{{ process.police_station?.police_station }}</td>
												<td>{{ process.crime_number }}</td>
												<td>{{ process.section_of_law }}</td>
												<td>{{ process.court_name }}</td>
												<td>{{ process.cc_sc_number }}</td>
												<td>{{ process.issued_date }}</td>
												<td>{{ process.status }}</td>
												<td>{{ process.next_hearing_date }}</td>
												<td><button class="btn btn-sm btn-primary"
														@click="editProcess(process.process_id)"><i
															class="ri-pencil-line"></i></button></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="goonda">
							<div class="row row-cols-auto g-xl-4 gap-1  pt-2">
								<div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#goondaModal"
									@click="getGoondas(rowdy.rowdy_id, 'goonda')">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.goonda_count }}
									</h6>
									<label class="card-title fw-semibold mb-2">Goonda Act</label>
								</div>
								<div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#goondaModal"
									@click="getGoondas(rowdy.rowdy_id, 'externment')">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{ rowdy?.externment_count
										}}</h6>
									<label class="card-title fw-semibold mb-2">Externment</label>
								</div>
								<div class="col mt-2" data-bs-toggle="modal" data-bs-target="#goondaModal"
									@click="getGoondas(rowdy.rowdy_id)">
									<h6 class="card-value fs-30 mb-1 justify-content-center">{{
										rowdy?.goonda_externment_count }}</h6>
									<label class="card-title fw-semibold mb-2">Total</label>
								</div>
							</div>
							<div class="row">
								<div class="col-md">
									<router-link :to="'/rowdy_goonda_create/' + rowdy.rowdy_id"
										class="btn btn-sm btn-primary float-end">Add Goonda/Externment/KCOCA
										Act</router-link>
								</div>
							</div>
							<div class="card small mt-2">
								<div class="table-responsive" style="overflow-y:scroll; height: 45vh;">
									<table class="table table-hover table-sm table-bordered table-striped mb-0">
										<thead>
											<tr>
												<th>SL NO</th>
												<th>Type</th>
												<th>Police Station</th>
												<th>Person Name</th>
												<th>Father Name</th>
												<th>Address</th>
												<th>Activities</th>
												<th>Order No</th>
												<th v-if="goonda_act">Approved/Rejected</th>
												<th v-else>Which Place</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="goonda, key in goondas">
												<td>{{ key + 1 }}</td>
												<td>{{ goonda.type }}</td>
												<td>{{ goonda.police_station.police_station }}</td>
												<td>{{ goonda.person_name }}</td>
												<td>{{ goonda.father_name }}</td>
												<td>{{ goonda.address }}</td>
												<td>{{ goonda.activities }}</td>
												<td>{{ goonda.order_no }}</td>
												<td v-if="goonda_act">{{ goonda.approved_status }}</td>
												<td v-else>{{ goonda.which_place }}</td>
												<td><button class="btn btn-sm btn-primary"
														@click="editGoonda(goonda.goonda_id)"><i
															class="ri-pencil-line"></i></button></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="gangs">
							<div class="row mt-2 g-3 text-center border-top1">
								<div class="col mt-2 border-right">
									<h3 class="mb-0">02</h3>
									<label class="card-title fw-semibold text-secondary mb-2">Goonda Act</label>
								</div>
								<div class="col mt-2 border-right">
									<h3 class="mb-0">01</h3>
									<label class="card-title fw-semibold text-secondary mb-2">Externment</label>
								</div>
								<div class="col mt-2">
									<h3 class="mb-0">43</h3>
									<label class="card-title fw-semibold text-secondary mb-2">Total</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="exampleModal">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Rowdy Images</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-2" style="display: flex; align-items: center;">
							<button v-if="this.shift.index > 0" class="btn btn-sm btn-outline-primary float-start"
								@click="shifLeft()"> <i class="ri-arrow-left-line"></i> </button>
						</div>
						<div class="col-8">
							<img class="modal-img" :src="shift.image" alt="" />
						</div>
						<div class="col-2" style="display: flex; align-items: center;">
							<button v-if="shift.index <= rowdy_images.length - 2"
								class="btn btn-sm btn-outline-primary float-end" @click="shiftRight()"> <i
									class="ri-arrow-right-line"></i> </button>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="casesModal">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">CASES</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-sm table-bordered table-striped">
							<thead>
								<tr>
									<th>SL NO</th>
									<th>Police Station</th>
									<th>Crime Number</th>
									<th>Section of Law</th>
									<th>Crime Head</th>
									<th>Victim/Deceased</th>
									<th>Accomplices Names</th>
									<th>Case Status</th>
									<th>Court Name</th>
									<th>CC / SC Number</th>
									<th>Next Hearing Date</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="rowdy_case, key in cases">
									<td>{{ key + 1 }}</td>
									<td>{{ rowdy_case.police_station?.police_station }}</td>
									<td>{{ rowdy_case.crime_number }}</td>
									<td>{{ rowdy_case.section_of_law }}</td>
									<td>{{ rowdy_case.crime_head }}</td>
									<td>{{ rowdy_case.victim_deceased }}</td>
									<td>{{ rowdy_case.accomplices_names }}</td>
									<td>{{ rowdy_case.case_status }}</td>
									<td>{{ rowdy_case.court_name }}</td>
									<td>{{ rowdy_case.cc_sc_number }}</td>
									<td>{{ rowdy_case.next_hearing_date }}</td>
									<td><button class="btn btn-sm btn-primary"
											@click="editIpcCase(rowdy_case.rowdies_case_id)"><i
												class="ri-pencil-line"></i></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
					<!-- <button type="button" class="btn btn-primary">Save changes</button> -->
				</div>
			</div>
		</div>
	</div>
	<!-- end of cases modal -->

	<!-- process modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="processModal">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Process</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-sm table-bordered table-striped">
							<thead>
								<tr>
									<th>SL NO</th>
									<th>Process Type</th>
									<th>Police Station</th>
									<th>Crime Number</th>
									<th>Section of Law</th>
									<th>Court Name</th>
									<th>CC / SC Number</th>
									<th>Issued Date</th>
									<th>Served/Unserved</th>
									<th>Next Hearing Date</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="process, key in processes">
									<td>{{ key + 1 }}</td>
									<td>{{ process.process_type }}</td>
									<td>{{ process.police_station?.police_station }}</td>
									<td>{{ process.crime_number }}</td>
									<td>{{ process.section_of_law }}</td>
									<td>{{ process.court_name }}</td>
									<td>{{ process.cc_sc_number }}</td>
									<td>{{ process.issued_date }}</td>
									<td>{{ process.status }}</td>
									<td>{{ process.next_hearing_date }}</td>
									<td><button class="btn btn-sm btn-primary"
											@click="editProcess(process.process_id)"><i
												class="ri-pencil-line"></i></button></td>
								</tr>
							</tbody>
						</table>
					</div>

				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="process_modal_close">Close</button>
					<!-- <button type="button" class="btn btn-primary">Save changes</button> -->
				</div>
			</div>
		</div>
	</div>
	<!-- end of process modal -->

	<!-- goonda modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="goondaModal">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Goonda / Externment</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-sm table-bordered table-striped">
							<thead>
								<tr>
									<th>SL NO</th>
									<th>Type</th>
									<th>Police Station</th>
									<th>Person Name</th>
									<th>Father Name</th>
									<th>Address</th>
									<th>Activities</th>
									<th>Order No</th>
									<th v-if="goonda_act">Approved/Rejected</th>
									<th v-else>Which Place</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="goonda, key in goondas">
									<td>{{ key + 1 }}</td>
									<td>{{ goonda.type }}</td>
									<td>{{ goonda.police_station.police_station }}</td>
									<td>{{ goonda.person_name }}</td>
									<td>{{ goonda.father_name }}</td>
									<td>{{ goonda.address }}</td>
									<td>{{ goonda.activities }}</td>
									<td>{{ goonda.order_no }}</td>
									<td v-if="goonda_act">{{ goonda.approved_status }}</td>
									<td v-else>{{ goonda.which_place }}</td>
									<td><button class="btn btn-sm btn-primary" @click="editGoonda(goonda.goonda_id)"><i
												class="ri-pencil-line"></i></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" ref="goonda_modal_close"
						data-bs-dismiss="modal">Close</button>
					<!-- <button type="button" class="btn btn-primary">Save changes</button> -->
				</div>
			</div>
		</div>
	</div>
	<!-- end of process modal -->

	<!-- Goonda act / externment modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="ipcModal">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">IPC {{ ipc }}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-sm table-bordered table-striped">
							<thead>
								<tr>
									<th>SL NO</th>
									<th>Police Station</th>
									<th>Crime Number</th>
									<th>Section of Law</th>
									<th>Crime Head</th>
									<th>Victim/Deceased</th>
									<th>Accomplices Names</th>
									<th>Case Status</th>
									<th>Court Name</th>
									<th>CC / SC Number</th>
									<th>Next Hearing Date</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="ipc_case, key in ipc_cases">
									<td>{{ key + 1 }}</td>
									<td>{{ ipc_case.police_station?.police_station }}</td>
									<td>{{ ipc_case.crime_number }}</td>
									<td>{{ ipc_case.section_of_law }}</td>
									<td>{{ ipc_case.crime_head }}</td>
									<td>{{ ipc_case.victim_deceased }}</td>
									<td>{{ ipc_case.accomplices_names }}</td>
									<td>{{ ipc_case.case_status }}</td>
									<td>{{ ipc_case.court_name }}</td>
									<td>{{ ipc_case.cc_sc_number }}</td>
									<td>{{ ipc_case.next_hearing_date }}</td>
									<td><button class="btn btn-sm btn-primary"
											@click="editIpcCase(ipc_case.rowdies_case_id)"><i
												class="ri-pencil-line"></i></button></td>
								</tr>
							</tbody>
						</table>
					</div>

				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="ipc_modal_close">Close</button>
					<!-- <button type="button" class="btn btn-primary">Save changes</button> -->
				</div>
			</div>
		</div>
	</div>
	<!-- History information modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="infoModal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Edited History</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-sm table-bordered table-striped">
							<thead>
								<tr>
									<th>SL NO</th>
									<th>NEW {{ edited_field_name }}</th>
									<th>OLD {{ edited_field_name }}</th>
									<th>Edited By</th>
									<th>Date Time</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="edited_history.length" v-for="edited, key in edited_history" :key="key">
									<td>{{ key + 1 }}</td>
									<td v-if="key > 0">{{ edited_history[key - 1].field_value }}</td>
									<td v-else>{{ new_record.field_value }}</td>
									<td>{{ edited.field_value }}</td>
									<td>{{ edited.edited_by.name }}</td>
									<td>{{ edited.edited_date_time }}</td>
								</tr>
								<tr v-else>
									<td colspan="5" class="text-center">No records to display!...</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
let Search = require("@/components/Search.vue").default;

export default {
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
		Search
	},

	data() {
		return {
			police_station_id: '',
			openned_police_station_id: '',
			police_stations: [],
			rowdy: {
				rowdy_id: '',
				rowdy_sheeter_name: '',
				rowdy_sheet_openned_date: '',
				rowdy_sheet_openned_order_copy: '',
				date_of_entry: '',
				closed_transfer_died_details: '',
				regarded_order_copy_file: '',
				rowdy_sheet_closed_date: '',
				rowdy_sheet_closed_order_copy: '',
				is_active: '',
				// is_approved: '',
				fpb_coid_number: '',
				category: '',
				where_abouts: '',
				police_station_id: '',
				police_station: '',
				alias: '',
				father_name: '',
				present_address: '',
				permanent_address: '',
				fathers_address: '',
				mothers_address: '',
				date_of_birth: null,
				place_of_birth: '',
				height: '',
				gender: '',
				marital_status: '',
				educational_qualification: '',
				school_college: '',
				locality: '',
				police_station_limit: '',
				mobile_no: '',
				details_of_mobile: '',
				aadhaar_number: '',
				passport_no: '',
				voter_card_no: '',
				voter_card_details: '',
				pan_number: '',
				ration_card_number: '',
				driving_licence_details: '',
				native: '',
				present_occupation_details: '',
				previous_occupation_details: '',
				frequently_dialed_contact: '',
				social_media_accounts: '',
				visited_website_details: '',
				languages_known: '',
				caste_religion_nationality: '',
				speech_mother_tongue: '',
				tatoo_mark: '',
				visible_identification_marks: '',
				habits: '',
				rowdy_status: '',
				frequently_visited_places: '',
				rowdy_background: '',
				rowdy_present_activities: '',
				voice_sample: '',
				video_sample: '',
				rowdy_photo_1: '',
				rowdy_photo_2: '',
				rowdy_photo_3: '',
				spouse_details: '',
				spouse_natvie_place: '',
				children_details: '',
				children_occupation_details: '',
				father_in_law_details: '',
				brother_details: '',
				sister_details: '',
				brother_in_law_details: '',
				emotionally_attached_person_details: '',
				friend_details: '',
				bank_account_details: '',
				vehicle_owned_details: '',
				vehicles_used_for_offence: '',
				movable_immovable_property_details: '',
				investment_details: '',
				locker_details: '',
				financer_details: '',
				tailor_details: '',
				garage_details: '',
				motor_mechanic_details: '',
				political_affiliation_details: '',
				influencer_details: '',
				barber_details: '',
				doctor_clinic_frequently_visited: '',
			},
			rowdy_category: {
				openned_date: '',
				police_station_id: '',
				police_station: '',
				category: '',
				closed_date: '',
				where_abouts: ''
			},
			rowdy_categories: [],
			deleted_categories: [],

			errors: [],
			image: '',
			images: [],
			cases: [],
			processes: [],
			goondas: [],
			ipc_cases: [],
			ipc: '',
			goonda_act: false,
			path: '',
			update_status: true,
			new_record: '',
			edited_history: [],
			edited_field_name: '',
			rowdy_images: [],
			shift: {
				index: 0,
				image: ''
			}
		}
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.getPoliceStations();
			if (sessionStorage.getItem('paths')) {
				vm.paths = JSON.parse(sessionStorage.getItem('paths'));
				if (vm.paths[1]) {
					vm.path = vm.paths[1];
				} else {
					vm.paths[1] = from.path;
					vm.path = from.path;
					sessionStorage.setItem('paths', JSON.stringify(vm.paths));
				}
			}
			vm.rowdy.rowdy_id = to.params.rowdy_id;
			vm.getRowdy(to.params.rowdy_id);
			vm.getRowdyImages();
		})
	},

	methods: {
		getRowdy(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getRowdy', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.rowdy = response.data.data;
					this.rowdy_categories = this.rowdy.rowdy_categories;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getCases(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getCases', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.cases = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getIpcCases(rowdy_id, case_type) {
			let vm = this;
			this.ipc = case_type;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getIpcCases', data: { rowdy_id: rowdy_id, case_type: case_type } })
				.then(response => {
					loader.hide();
					this.ipc_cases = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getProcesses(rowdy_id, process_type) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getProcesses', data: { rowdy_id: rowdy_id, process_type: process_type } })
				.then(response => {
					loader.hide();
					this.processes = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getGoondas(rowdy_id, type) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getGoondas', data: { rowdy_id: rowdy_id, type: type } })
				.then(response => {
					loader.hide();
					this.goondas = response.data.data;
					if (this.goondas.length) {
						if (this.goondas[0].type == 'Goonda') {
							this.goonda_act = true;
						} else {
							this.goonda_act = false;
						}
					}
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		editIpcCase(rowdies_case_id) {
			this.$refs.ipc_modal_close.click();
			this.$router.push('/rowdy_case_update/' + this.rowdy.rowdy_id + '/' + rowdies_case_id);
		},

		editProcess(process_id) {
			this.$refs.process_modal_close.click();
			this.$router.push('/rowdy_process_update/' + this.rowdy.rowdy_id + '/' + process_id);
		},

		editGoonda(goonda_id) {
			this.$refs.goonda_modal_close.click();
			this.$router.push('/rowdy_goonda_update/' + this.rowdy.rowdy_id + '/' + goonda_id);
		},

		getPoliceStations() {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getPoliceStations' })
				.then(response => {
					loader.hide();
					this.police_stations = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		onImageChange(e) {
			let vm = this;
			vm.image = e.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(vm.image);
			reader.onload = (e) => {
				vm.rowdy.rowdy_sheeter_photo = e.target.result;
			};
		},

		updateRowdy() {
			let vm = this;
			this.rowdy.police_station_id = this.police_station_id;
			let data = new FormData();
			data.append('rowdy_id', this.rowdy.rowdy_id);
			data.append('rowdy_sheeter_name', this.rowdy.rowdy_sheeter_name);
			if (this.rowdy.rowdy_sheet_openned_date) {
				data.append('rowdy_sheet_openned_date', this.rowdy.rowdy_sheet_openned_date);
			}
			data.append('rowdy_sheet_openned_order_copy', this.rowdy.rowdy_sheet_openned_order_copy);
			if (this.rowdy.date_of_entry) {
				data.append('date_of_entry', this.rowdy.date_of_entry);
			}
			data.append('closed_transfer_died_details', this.rowdy.closed_transfer_died_details);
			data.append('regarded_order_copy_file', this.rowdy.regarded_order_copy_file);
			if (this.rowdy.rowdy_sheet_closed_date) {
				data.append('rowdy_sheet_closed_date', this.rowdy.rowdy_sheet_closed_date);
			}
			data.append('rowdy_sheet_closed_order_copy', this.rowdy.rowdy_sheet_closed_order_copy);
			if (this.rowdy.is_active) {
				data.append('is_active', this.rowdy.is_active);
			}
			if (this.rowdy.is_approved) {
				data.append('is_approved', this.rowdy.is_approved);
			}
			data.append('fpb_coid_number', this.rowdy.fpb_coid_number);
			data.append('category', this.rowdy.category);
			data.append('where_abouts', this.rowdy.where_abouts);
			if (this.rowdy.police_station_id) {
				data.append('police_station_id', this.rowdy.police_station_id);
			}
			data.append('police_station', this.rowdy.police_station);
			data.append('alias', this.rowdy.alias);
			data.append('father_name', this.rowdy.father_name);
			data.append('present_address', this.rowdy.present_address);
			data.append('permanent_address', this.rowdy.permanent_address);
			data.append('fathers_address', this.rowdy.fathers_address);
			data.append('mothers_address', this.rowdy.mothers_address);
			if (this.rowdy.date_of_birth) {
				data.append('date_of_birth', this.rowdy.date_of_birth);
			}
			data.append('place_of_birth', this.rowdy.place_of_birth);
			data.append('height', this.rowdy.height);
			data.append('gender', this.rowdy.gender);
			data.append('marital_status', this.rowdy.marital_status);
			data.append('educational_qualification', this.rowdy.educational_qualification);
			data.append('school_college', this.rowdy.school_college);
			data.append('locality', this.rowdy.locality);
			data.append('police_station_limit', this.rowdy.police_station_limit);
			data.append('mobile_no', this.rowdy.mobile_no);
			data.append('details_of_mobile', this.rowdy.details_of_mobile);
			data.append('aadhaar_number', this.rowdy.aadhaar_number);
			data.append('passport_no', this.rowdy.passport_no);
			data.append('voter_card_no', this.rowdy.voter_card_no);
			data.append('voter_card_details', this.rowdy.voter_card_details);
			data.append('pan_number', this.rowdy.pan_number);
			data.append('ration_card_number', this.rowdy.ration_card_number);
			data.append('driving_licence_details', this.rowdy.driving_licence_details);
			data.append('native', this.rowdy.native);
			data.append('present_occupation_details', this.rowdy.present_occupation_details);
			data.append('previous_occupation_details', this.rowdy.previous_occupation_details);
			data.append('frequently_dialed_contact', this.rowdy.frequently_dialed_contact);
			data.append('social_media_accounts', this.rowdy.social_media_accounts);
			data.append('visited_website_details', this.rowdy.visited_website_details);
			data.append('languages_known', this.rowdy.languages_known);
			data.append('caste_religion_nationality', this.rowdy.caste_religion_nationality);
			data.append('speech_mother_tongue', this.rowdy.speech_mother_tongue);
			data.append('tatoo_mark', this.rowdy.tatoo_mark);
			data.append('visible_identification_marks', this.rowdy.visible_identification_marks);
			data.append('habits', this.rowdy.habits);
			data.append('rowdy_status', this.rowdy.rowdy_status);
			data.append('frequently_visited_places', this.rowdy.frequently_visited_places);
			data.append('rowdy_background', this.rowdy.rowdy_background);
			data.append('rowdy_present_activities', this.rowdy.rowdy_present_activities);
			data.append('voice_sample', this.rowdy.voice_sample);
			data.append('video_sample', this.rowdy.video_sample);
			data.append('rowdy_photo_1', this.rowdy.rowdy_photo_1);
			data.append('rowdy_photo_2', this.rowdy.rowdy_photo_2);
			data.append('rowdy_photo_3', this.rowdy.rowdy_photo_3);
			data.append('spouse_details', this.rowdy.spouse_details);
			data.append('spouse_natvie_place', this.rowdy.spouse_natvie_place);
			data.append('children_details', this.rowdy.children_details);
			data.append('children_occupation_details', this.rowdy.children_occupation_details);
			data.append('father_in_law_details', this.rowdy.father_in_law_details);
			data.append('brother_details', this.rowdy.brother_details);
			data.append('sister_details', this.rowdy.sister_details);
			data.append('brother_in_law_details', this.rowdy.brother_in_law_details);
			data.append('emotionally_attached_person_details', this.rowdy.emotionally_attached_person_details);
			data.append('friend_details', this.rowdy.friend_details);
			data.append('bank_account_details', this.rowdy.bank_account_details);
			data.append('vehicle_owned_details', this.rowdy.vehicle_owned_details);
			data.append('vehicles_used_for_offence', this.rowdy.vehicles_used_for_offence);
			data.append('movable_immovable_property_details', this.rowdy.movable_immovable_property_details);
			data.append('investment_details', this.rowdy.investment_details);
			data.append('locker_details', this.rowdy.locker_details);
			data.append('financer_details', this.rowdy.financer_details);
			data.append('tailor_details', this.rowdy.tailor_details);
			data.append('garage_details', this.rowdy.garage_details);
			data.append('motor_mechanic_details', this.rowdy.motor_mechanic_details);
			data.append('political_affiliation_details', this.rowdy.political_affiliation_details);
			data.append('influencer_details', this.rowdy.influencer_details);
			data.append('barber_details', this.rowdy.barber_details);
			data.append('doctor_clinic_frequently_visited', this.rowdy.doctor_clinic_frequently_visited);
			if (this.image != 'avatar.jpg' || this.image != '') {
				data.append('rowdy_sheeter_image', this.image);
			}
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'updateRowdy', data: data })
				.then(response => {
					loader.hide();
					this.rowdy = response.data.rowdy;
					this.$store.dispatch('success', response.data.message);
					vm.$router.push('/view_rowdy/' + this.rowdy.rowdy_id);
					for (let key in this.rowdy) {
						if (this.rowdy[key] == null) {
							this.rowdy[key] = '';
						}
					}
					this.errors = [];
					this.updateRowdyCategories();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getRowdyEditHistory(field_name) {
			this.edited_field_name = field_name;
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getRowdyEditHistory', data: { rowdy_id: this.rowdy.rowdy_id } })
				.then(response => {
					loader.hide();
					this.edited_history = response.data.history;
					this.new_record = response.data.new;
					this.new_record.field_value = this.new_record[field_name];
					let temp = this.edited_history.filter(ele => {
						ele.field_value = ele[field_name];
						return ele[field_name] != null;
					});
					this.edited_history = temp;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getRowdyImages() {
			let vm = this;
			let loader = vm.$loading.show();
			this.$store.dispatch('post', { uri: 'getRowdyImages', data: { rowdy_id: this.rowdy.rowdy_id } })
				.then(response => {
					loader.hide();
					this.rowdy_images = response.data.data;
					if (this.rowdy_images.length) {
						this.shift.index = 0;
						this.shift.image = this.rowdy_images[0].image;
					}
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		uploadMultipleImages() {
			let image_data = new FormData();
			image_data.append('rowdy_id', this.rowdy.rowdy_id);
			for (var i = 0; i < this.$refs.file.files.length; i++) {
				let file = this.$refs.file.files[i];
				image_data.append('images[' + i + ']', file);
			}

			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'uploadMultipleImages', data: image_data })
				.then(response => {
					loader.hide();
					this.$store.dispatch('success', response.data.message);
					this.$router.go();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		shifLeft() {
			if (this.rowdy_images.length) {
				this.shift.index--;
				this.shift.image = this.rowdy_images[this.shift.index].image;
			}
		},

		shiftRight() {
			if (this.rowdy_images.length) {
				this.shift.index++;
				this.shift.image = this.rowdy_images[this.shift.index].image;
			}
		},

		updateRowdyCategories() {
			let vm = this;
			let loader = this.$loading.show();
			let data = {
				rowdy_id: this.rowdy.rowdy_id,
				rowdy_categories: this.rowdy_categories,
				deleted_categories: this.deleted_categories
			}
			this.$store.dispatch('post', { uri: 'updateRowdyCategories', data: data })
				.then(response => {
					loader.hide();
					this.getRowdy(this.rowdy.rowdy_id);
					vm.$router.push('/view_rowdy/' + this.rowdy.rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		addRowdyCategory() {
			let police_station = this.police_stations.find(ele => { return ele.police_station_id == this.openned_police_station_id });
			if (police_station) {
				let temp = this.rowdy_categories.find(ele => { return ele.police_station_id == police_station.police_station_id });
				if (temp) {
					if (temp.category != this.rowdy_category.category) {
						alert('Different categories in same police station!...');
						return;
					}
				}
				this.rowdy_category.police_station = police_station.police_station;
				this.rowdy_category.police_station_id = this.openned_police_station_id;
			} else {
				alert('Please select police station!');
				return;
			}
			this.rowdy_category.rowdy_category_id = null;
			if (this.rowdy_category.category == 'A') {
				let temp = this.rowdy_categories.find(ele => { return ele.category == 'A' });
				if (temp) {
					alert('Category A is already selected!');
					return;
				}
			}

			let obj = Object.assign({}, this.rowdy_category);
			this.rowdy_categories.push(obj);
		},

		removeRowdyCategory(category) {
			if (category.rowdy_category_id) {
				this.deleted_categories.push(category.rowdy_category_id);
			}
			this.rowdy_categories = this.rowdy_categories.filter(ele => ele != category);
		}
	}
}
</script>
<style scoped>
.modal-img {
	width: -webkit-fill-available;
}

.modal-close {
	background-color: white;
	border-radius: 50%;
	padding: 5px;
}

input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

textarea {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.cases_box {
	padding: 0px, 24px, 0px, 24px
}

.text-white {
	color: white !important;
}

.rowdy_categories {
	color: blue;
}
</style>
